import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  Grid,
  Avatar,
  Paper,
  FormLabel,
  TextField,
  Divider,
  Snackbar,
} from "@mui/material";
import { Google } from "../../../assets/svg/Google";
import { styled } from "@mui/system";
import {
  fetchUserProfile,
  editUserProfile,
  selectAccount,
} from "../../../store/slices/accountSlice";
import countriesData from "./countries.json";

const InputField = styled(TextField)({
  marginBottom: "20px",
  "& .MuiInputBase-root": {
    height: "40px",
  },
});

const EditProfileSection = () => {
  const dispatch = useDispatch();
  const { data: userData, isLoading, error } = useSelector(selectAccount);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    country: "",
    city: "",
    state: "",
    zip_code: "",
    address: "",
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const countries = countriesData.countries;

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (userData) {
      setFormData({
        firstname: userData.firstname || "",
        lastname: userData.lastname || "",
        email: userData.email || "",
        phone: userData.phone || "",
        country: userData.country || "",
        city: userData.city || "",
        state: userData.state || "",
        zip_code: userData.zip_code || "",
        address: userData.address || "",
      });
    }
  }, [userData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(editUserProfile(formData)).unwrap();
      setSnackbar({ open: true, message: "Profile updated successfully!" });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to update profile. Please try again.",
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const labelStyle = {
    color: "#80838D",
    fontSize: "14px",
    marginBottom: "4px",
    display: "inline-block",
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ maxWidth: 800, margin: "auto", p: 3, paddingTop: "64px" }}
      >
        <Typography
          variant="h5"
          sx={{ mb: 3, color: "#202020", fontSize: "24px", fontWeight: "400" }}
        >
          Edit Profile
        </Typography>

        <Paper
          sx={{
            p: 2,
            pb: 0,
            pt: 0,
            display: "flex",
            alignItems: "center",
            mb: 3,
            bgcolor: "#EDF2FE",
            height: "72px",
            boxShadow: "unset",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pb: 0 }}>
            <Avatar
              src={userData?.avatar_url || "/path-to-default-image.jpg"}
              sx={{ width: 48, height: 48, mr: 2 }}
            />
            <Box>
              <Typography
                sx={{ color: "#202020", fontWeight: 500, fontSize: "14px" }}
                variant="h6"
              >
                {`${formData.firstname} ${formData.lastname}`}
              </Typography>
              <Box sx={{ display: "flex", mt: "5px", alignItems: "center" }}>
                <Google sx={{ width: "14px", height: "14px" }} />
                <Typography
                  sx={{ marginLeft: "12px" }}
                  variant="body2"
                  color="text.secondary"
                >
                  {formData.email}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
        <Divider sx={{ width: "100%", mb: "24px" }} />
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ pr: { sm: 2 } }}>
            <FormLabel component="legend" style={labelStyle}>
              First name
            </FormLabel>
            <InputField
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              placeholder="Enter First name"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="legend" style={labelStyle}>
              Last name
            </FormLabel>
            <InputField
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              placeholder="Enter Last name"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ pr: { sm: 2 } }}>
            <FormLabel component="legend" style={labelStyle}>
              Email
            </FormLabel>
            <InputField
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter Email"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="legend" style={labelStyle}>
              WhatsApp Number
            </FormLabel>
            <InputField
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="876-987-9876"
              variant="outlined"
              fullWidth
              sx={{ mb: "unset" }}
              size="small"
            />
            <Typography
              sx={{
                color: "#8E8E93",
                mb: "20px",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              WhatsApp alerts commute time, taxes, real time monitoring
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ pr: { sm: 2 } }}>
            <FormLabel component="legend" style={labelStyle}>
              Country
            </FormLabel>
            <Select
              name="country"
              value={formData.country}
              onChange={handleChange}
              displayEmpty
              fullWidth
              size="small"
              sx={{
                height: "40px",
                marginBottom: "20px",
                color: "#8E8E93",
                pl: "6px",
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="legend" style={labelStyle}>
              City
            </FormLabel>
            <InputField
              name="city"
              value={formData.city}
              onChange={handleChange}
              placeholder="Enter City"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ pr: { sm: 2 } }}>
            <FormLabel component="legend" style={labelStyle}>
              State
            </FormLabel>
            <Select
              name="state"
              value={formData.state}
              onChange={handleChange}
              displayEmpty
              fullWidth
              size="small"
              sx={{
                height: "40px",
                marginBottom: "20px",
                color: "#8E8E93",
                pl: "6px",
              }}
            >
              <MenuItem value="" disabled>
                Select State
              </MenuItem>
              {/* Add state options here */}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel component="legend" style={labelStyle}>
              ZIP
            </FormLabel>
            <InputField
              name="zip_code"
              value={formData.zip_code}
              onChange={handleChange}
              placeholder="Enter ZIP"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel component="legend" style={labelStyle}>
              Address
            </FormLabel>
            <InputField
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Enter Address"
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            bgcolor: "#4a5af9",
            color: "white",
            py: 1.5,
            fontSize: "18px",
            boxShadow: "none",
            fontWeight: 500,
            height: "48px",
            mt: "20px",
            marginBottom: "64px",
          }}
        >
          Save Profile
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "68px",
          borderTop: "1px solid rgba(0, 0, 51, 0.06)",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            fontSize: "14px",
            fontWeight: 400,
            textAlign: "center",
          }}
          variant="body2"
          color="textSecondary"
        >
          © 2024 Home Ai, LLC
        </Typography>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />
    </>
  );
};

export default EditProfileSection;
