import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const ConfirmationModal = ({
  open,
  selectedOption,
  handleClose,
  handleConfirm,
  viewingsCount,
  setIsAfterViewings,
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isNotImmediately, setIsNotImmediately] = useState(false);

  useEffect(() => {
    if (selectedOption !== "immediately") {
      setIsNotImmediately(true);
    } else {
      setIsNotImmediately(false);
    }
  }, [selectedOption]);

  const onConfirm = () => {
    setIsConfirmed(true);
    handleConfirm();
  };

  const onClose = () => {
    setIsConfirmed(false);
    handleClose();
    if (isNotImmediately) {
      setIsAfterViewings(viewingsCount);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          minHeight: "200px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 0,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!isNotImmediately && !isConfirmed && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "16px 40px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "26px",
                color: "#202020",
              }}
            >
              Take it out the ad
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            padding:
              isNotImmediately || isConfirmed
                ? "48px 30px 0 48px"
                : "48px 30px",
            overflowY: "auto",
            maxHeight: "600px",
            flexGrow: 1,
          }}
        >
          <Box sx={{ display: "grid", gap: "24px", textAlign: "center" }}>
            {(isNotImmediately || isConfirmed) && (
              <Box
                sx={{
                  background: "#3E63DD",
                  width: "32px",
                  height: "32px",
                  padding: "12px",
                  borderRadius: "32px",
                  margin: "auto",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M29.0612 10.0606L13.0612 26.0606C12.9218 26.2005 12.7563 26.3114 12.5739 26.3872C12.3916 26.4629 12.1961 26.5018 11.9987 26.5018C11.8013 26.5018 11.6058 26.4629 11.4235 26.3872C11.2411 26.3114 11.0756 26.2005 10.9362 26.0606L3.9362 19.0606C3.79667 18.9211 3.68599 18.7555 3.61047 18.5732C3.53496 18.3909 3.49609 18.1955 3.49609 17.9981C3.49609 17.8008 3.53496 17.6054 3.61047 17.4231C3.68599 17.2408 3.79667 17.0752 3.9362 16.9356C4.07573 16.7961 4.24137 16.6854 4.42367 16.6099C4.60598 16.5344 4.80137 16.4955 4.9987 16.4955C5.19602 16.4955 5.39141 16.5344 5.57372 16.6099C5.75602 16.6854 5.92167 16.7961 6.0612 16.9356L11.9999 22.8744L26.9387 7.93815C27.2205 7.65636 27.6027 7.49805 28.0012 7.49805C28.3997 7.49805 28.7819 7.65636 29.0637 7.93815C29.3455 8.21994 29.5038 8.60213 29.5038 9.00065C29.5038 9.39916 29.3455 9.78136 29.0637 10.0631L29.0612 10.0606Z"
                    fill="white"
                  />
                </svg>
              </Box>
            )}
            <Typography
              id="confirmation-modal-description"
              sx={{ mt: 0, fontSize: "14px" }}
            >
              {isConfirmed ? (
                `The ad was took out`
              ) : (
                <>
                  {isNotImmediately
                    ? `Your ad will take it out after ${viewingsCount} viewings`
                    : "Are you sure want to take it out this ad?"}
                </>
              )}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 40px",
            borderTop:
              !isNotImmediately && !isConfirmed
                ? "1px solid rgba(0, 0, 0, 0.15)"
                : "",
          }}
        >
          {!isNotImmediately && !isConfirmed ? (
            <>
              <Button
                onClick={onClose}
                sx={{
                  color: "#002BB7C5",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => onConfirm()}
                color="primary"
              >
                Take it out
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={onClose}
              fullWidth
              sx={{
                backgroundColor: "transparent",
                color: "rgba(0, 43, 183, 0.77)",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
            >
              Got it
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
