import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import authService from "../../services/authService";

const initialState = {
  viewings: {
    data: [],
    isLoading: false,
    error: null,
  },
  requestViewing: {
    data: null,
    isLoading: false,
    error: null,
  },
  updateViewing: {
    data: null,
    isLoading: false,
    error: null,
  },
  deleteViewing: {
    data: null,
    isLoading: false,
    error: null,
  },
};

const API_URL = process.env.REACT_APP_BASE_URL;

export const requestViewing = createAsyncThunk(
  "viewing/request-viewing",
  async (body, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.post(`${API_URL}/viewing/`, body, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getViewings = createAsyncThunk(
  "viewing/get-viewings",
  async ({ userId, propertyId }, thunkAPI) => {
    try {
      const params =
        userId || propertyId
          ? `${userId ? `?user_id=${userId}&` : "?"}${
              propertyId ? `property_id=${propertyId}` : ""
            }`
          : "";
      const user = authService.getCurrentUser();
      const response = await axios.get(`${API_URL}/viewing/${params}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateViewing = createAsyncThunk(
  "viewing/update-viewing",
  async ({ viewingId, body }, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.put(
        `${API_URL}/viewing/${viewingId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const cancelViewing = createAsyncThunk(
  "viewing/cancel-viewing",
  async (viewingId, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.delete(`${API_URL}/viewing/${viewingId}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const viewingSlice = createSlice({
  name: "viewing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestViewing.pending, (state) => {
        state.requestViewing.isLoading = true;
        state.requestViewing.error = null;
      })
      .addCase(requestViewing.fulfilled, (state, action) => {
        state.requestViewing.isLoading = false;
        state.requestViewing.data = action.payload;
      })
      .addCase(requestViewing.rejected, (state, action) => {
        state.requestViewing.isLoading = false;
        state.requestViewing.error = action.payload;
      })

      .addCase(getViewings.pending, (state) => {
        state.viewings.isLoading = true;
        state.viewings.error = null;
      })
      .addCase(getViewings.fulfilled, (state, action) => {
        state.viewings.isLoading = false;
        state.viewings.data = action.payload;
      })
      .addCase(getViewings.rejected, (state, action) => {
        state.viewings.isLoading = false;
        state.viewings.error = action.payload;
      })

      .addCase(updateViewing.pending, (state) => {
        state.updateViewing.isLoading = true;
        state.updateViewing.error = null;
      })
      .addCase(updateViewing.fulfilled, (state, action) => {
        state.updateViewing.isLoading = false;
        state.updateViewing.data = action.payload;
      })
      .addCase(updateViewing.rejected, (state, action) => {
        state.updateViewing.isLoading = false;
        state.updateViewing.error = action.payload;
      })

      .addCase(cancelViewing.pending, (state) => {
        state.deleteViewing.isLoading = true;
        state.deleteViewing.error = null;
      })
      .addCase(cancelViewing.fulfilled, (state, action) => {
        state.deleteViewing.isLoading = false;
        state.deleteViewing.data = action.payload;
      })
      .addCase(cancelViewing.rejected, (state, action) => {
        state.deleteViewing.isLoading = false;
        state.deleteViewing.error = action.payload;
      });
  },
});

export const selectViewing = (state) => state.viewing;

export default viewingSlice.reducer;
