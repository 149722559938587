import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { theme } from "../../../theme/theme";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const FAQSection = () => {
  const faqData = [
    {
      category: "Subscriptions",
      questions: [
        {
          question:
            "Quis autem vel eum iure reprehenderit qui in ea voluptate?",
          answer:
            "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.",
        },
        {
          question:
            "Quis autem vel eum iure reprehenderit qui in ea voluptate?",
          answer:
            "Et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.",
        },
        {
          question:
            "Quis autem vel eum iure reprehenderit qui in ea voluptate?",
          answer:
            "Et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.",
        },
        {
          question:
            "Quis autem vel eum iure reprehenderit qui in ea voluptate?",
          answer:
            "Et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.",
        },
        {
          question:
            "Quis autem vel eum iure reprehenderit qui in ea voluptate?",
          answer:
            "Et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.",
        },
      ],
    },
    {
      category: "Lorem Ipsum",
      questions: [
        {
          question:
            "Quis autem vel eum iure reprehenderit qui in ea voluptate?",
          answer:
            "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.",
        },
        {
          question:
            "Quis autem vel eum iure reprehenderit qui in ea voluptate?",
          answer:
            "Et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.",
        },
        {
          question:
            "Quis autem vel eum iure reprehenderit qui in ea voluptate?",
          answer:
            "Et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.",
        },
        {
          question:
            "Quis autem vel eum iure reprehenderit qui in ea voluptate?",
          answer:
            "Et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.",
        },
        {
          question:
            "Quis autem vel eum iure reprehenderit qui in ea voluptate?",
          answer:
            "Et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.",
        },
      ],
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.background.lightBlue,
        pt: 8,
        pb: 2,
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          [theme.breakpoints.down("sm")]: {
            px: 2,
          },
        }}
      >
        <Typography
          variant="h5"
          fontWeight={700}
          fontSize={"35px"}
          align="center"
          gutterBottom
        >
          FAQ
        </Typography>
        {faqData.map((section, index) => (
          <Box key={index} mb={4}>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 700,
              }}
            >
              {section.category}
            </Typography>
            <List
              sx={{
                pl: 2,
              }}
            >
              {section.questions.map((item, idx) => (
                <ListItem key={idx} disableGutters>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 500,
                          color: "black",
                          fontSize: "16px",
                          fontWeight: 700,
                        }}
                      >
                        <FiberManualRecordIcon
                          sx={{
                            fontSize: "8px",
                            mr: 1,
                            ml: -3,
                          }}
                        />{" "}
                        {item.question}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          pt: 1,
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        {item.answer}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Container>
    </Box>
  );
};

export default FAQSection;
