import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import {
  AccountCircle as ProfileIcon,
  Payment as PaymentIcon,
  ExitToApp as LogoutIcon,
} from "@mui/icons-material";
import { UserSquare } from "../../assets/svg/UserSquare";
import { SignOut } from "../../assets/svg/SignOut";
import { CreditCard } from "../../assets/svg/CreditCard";
import { color } from "framer-motion";
import { useNavigate } from "react-router-dom";
import authService from "../../services/authService";

const StyledListItemText = styled(ListItemText)({
  ".MuiTypography-root": {
    color: "#C40006D3",
  },
});

const ProfileDropdown = ({ anchorEl, setAnchorEl, open, handleMouseLeave }) => {
  const navigate = useNavigate();

  const handleMenuClick = (action) => {
    if (action === "profile") return navigate("/my-account?section=property");

    if (action === "payment") return navigate("/my-account?section=payments");

    authService.logout();
    navigate("/");
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="profile-menu"
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        onMouseLeave: handleMouseLeave,
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 31,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={() => handleMenuClick("profile")}>
        <ListItemIcon sx={{ minWidth: "28px!important" }}>
          <UserSquare />
        </ListItemIcon>
        <ListItemText>Profile</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleMenuClick("payment")}>
        <ListItemIcon sx={{ minWidth: "28px!important" }}>
          <CreditCard />
        </ListItemIcon>
        <ListItemText>Payment</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleMenuClick("logout")}>
        <ListItemIcon sx={{ minWidth: "28px!important" }}>
          <SignOut />
        </ListItemIcon>
        <StyledListItemText>Log out</StyledListItemText>
      </MenuItem>
    </Menu>
  );
};

export default ProfileDropdown;
