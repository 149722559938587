import { Box, TextField } from "@mui/material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
const MessageField = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");
  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#ecf1fd",
          borderRadius: "5px",
          padding: "8px",
        }}
      >
        <TextField
          sx={{
            flex: 1,
            alignSelf: "flex-end",
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
              backgroundColor: "#ffffff",
            },
            "& .MuiInputBase-input": {
              padding: "8px",
              height: "20px",
            },
            "&:hover fieldset": {
              outline: "none",
            },
          }}
          placeholder="Type Something...."
          value={message}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
        <SendIcon
          onClick={handleSend}
          sx={{
            marginLeft: "5px",
            p: "10px",
            backgroundColor: "#3e63dd",
            color: "white",
            cursor: "pointer",
            borderRadius: "4px",
            height: "15px",
          }}
        />
      </Box>
    </Box>
  );
};

export default MessageField;
