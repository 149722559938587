import React, { useState, useEffect, useRef } from "react";
import { TextField, InputAdornment, Button } from "@mui/material";
import { theme } from "../../theme/theme";

const SearchBar = () => {
  const [value, setValue] = useState("");
  const [isStreaming, setIsStreaming] = useState(true);
  const timeoutRef = useRef(null);

  const phrases = [
    "blue kitchen",
    "steamer",
    "view of the lake",
    "luxury bathroom",
    "spacious garden",
    "cozy fireplace",
  ];

  useEffect(() => {
    let phraseIndex = 0;
    let charIndex = 0;
    let isTyping = true;

    const streamText = () => {
      if (!isStreaming) return;

      const currentPhrase = phrases[phraseIndex];

      if (isTyping) {
        if (charIndex < currentPhrase.length) {
          setValue(currentPhrase.slice(0, charIndex + 1));
          charIndex++;
        } else {
          isTyping = false;
          timeoutRef.current = setTimeout(streamText, 1000); // Pause at end of phrase
          return;
        }
      } else {
        if (charIndex > 0) {
          setValue(currentPhrase.slice(0, charIndex - 1));
          charIndex--;
        } else {
          isTyping = true;
          phraseIndex = (phraseIndex + 1) % phrases.length;
          timeoutRef.current = setTimeout(streamText, 500); // Pause before next phrase
          return;
        }
      }

      timeoutRef.current = setTimeout(streamText, 100);
    };

    streamText();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isStreaming]);

  const handleFocus = () => {
    setIsStreaming(false);
    setValue(""); // Clear the input value
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return (
    <TextField
      variant="outlined"
      value={value}
      onFocus={handleFocus}
      onChange={(e) => {
        if (!isStreaming) {
          setValue(e.target.value);
        }
      }}
      sx={{
        display: "flex",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        py: 0.5,
        width: "90%",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        color: `${theme.palette.primary.main}`,
        "& .MuiOutlinedInput-root": {
          paddingRight: "0",
          "& fieldset": {
            borderColor: "transparent",
          },
          "&:hover fieldset": {
            borderColor: "transparent",
          },
          "&.Mui-focused fieldset": {
            borderColor: "transparent",
          },
        },
        [theme.breakpoints.down("md")]: {
          py: 0,
        },
      }}
      inputProps={{
        style: {
          fontSize: 40,
          fontWeight: 700,
          px: "20px",
          color: `#3E63DD`,
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <span style={{ fontSize: 45, fontWeight: 400, color: "#000000" }}>
              I want a
            </span>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Button
              variant="contained"
              color="primary"
              sx={{
                height: "100%",
                py: 3,
                m: 2,
                borderRadius: 1,
                textTransform: "none",
                fontSize: "21px",
                [theme.breakpoints.down("md")]: {
                  py: 3,
                  m: 1,
                },
              }}
            >
              Start Your Search
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
