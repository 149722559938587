import React from "react";
import Layout from "../Layout/Layout";
import HowItWorks from "../../components/sections/workSection/WorkSection";
const Tenants = () => {
  const heading = "How it works for Tenants";
  const text =
    "Discover how Home AI transforms your apartment search into a seamless experience. Our intelligent platform leverages advanced algorithms to connect you with the best listings based on your unique preferences.";
  return (
    <Layout heading={heading} text={text} isTenants>
      <HowItWorks title={"Explore the Process"} isTenants />
    </Layout>
  );
};

export default Tenants;
