import React from "react";
import { Box, TextField, Button, Typography, FormLabel } from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { theme } from "../../theme/theme";
import authService from "../../services/authService";

const InputField = styled(TextField)({
  marginBottom: "20px",
});

const LoginForm = ({ onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      onSubmit(values);
      console.log("Login submitted", values);
    },
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      sx={{ mt: 1 }}
    >
      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        Email Address
      </FormLabel>
      <InputField
        size="small"
        fullWidth
        id="email"
        name="email"
        placeholder="Enter your Email"
        autoComplete="email"
        autoFocus
        {...formik.getFieldProps("email")}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        variant="outlined"
      />

      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        Password
      </FormLabel>
      <InputField
        size="small"
        fullWidth
        name="password"
        placeholder="Enter your Password"
        type="password"
        id="password"
        autoComplete="current-password"
        {...formik.getFieldProps("password")}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        variant="outlined"
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, float: "right", width: "auto" }}
        disabled={!formik.isValid || formik.isSubmitting}
      >
        Sign In
      </Button>
    </Box>
  );
};

export default LoginForm;
