import React from "react";
import Layout from "../Layout/Layout";
import {
  Grid,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/system";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { theme } from "../../theme/theme";

const ContactContainer = styled(Box)({
  backgroundColor: "#f7f9ff",
  padding: "20px",
});

const InnerContainer = styled(Grid)({
  backgroundColor: "white",
  borderRadius: "8px",
  display: "flex",
  alignItems: "stretch",
  border: "1px solid #d2dbf8",
  // height: '100%'
});

const InfoPaper = styled(Box)(({ theme }) => ({
  backgroundColor: "#3F63EC",
  padding: "30px",
  flex: 1,
  color: "white",
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  [theme.breakpoints.up("md")]: {
    minHeight: "470px", // Apply minHeight only on screens 900px or above
  },
}));

const InfoPaperBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    marginTop: "60px",
    marginBottom: "60px",
  },
}));

const FormPaper = styled(Box)({
  backgroundColor: "#fff",
  padding: "20px",
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const InputField = styled(TextField)({
  marginBottom: "20px",
});

const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    width: "20px",
    height: "20px",
  },
}));

const iconStyle = {
  fontSize: "15px",
  color: "white",
};

const upperIconStyle = {
  fontSize: "25px",
  color: "white",
  paddingRight: "10px",
};

const iconWrapperStyle = {
  backgroundColor: theme.palette.background.iconColor, // Dark blue color for the circle
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

const Contact = () => {
  return (
    <Layout>
      <ContactContainer>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} md={10} sx={{ paddingBottom: "50px" }}>
            <Typography
              variant="h2"
              sx={{ paddingBottom: "30px" }}
              align="center"
            >
              Contact Us
            </Typography>
            <Typography variant="body1" align="center">
              Any question or remarks? Just write us a message!
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <InnerContainer container spacing={2}>
              <Grid item xs={12} md={4}>
                <InfoPaper elevation={3}>
                  <Typography
                    variant="h6"
                    style={{ color: "white", fontWeight: "bold" }}
                    gutterBottom
                  >
                    Contact Information
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: "white" }}
                    gutterBottom
                  >
                    Say something to start a live chat!
                  </Typography>
                  <InfoPaperBox>
                    <Box sx={{ display: "flex", alignItems: "center" }} mt={3}>
                      <PhoneOutlinedIcon sx={upperIconStyle} />
                      <Typography
                        variant="body1"
                        style={{ color: "white" }}
                        gutterBottom
                      >
                        +1012 3456 789
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }} mt={3}>
                      <EmailOutlinedIcon sx={upperIconStyle} />
                      <Typography
                        variant="body1"
                        style={{ color: "white" }}
                        gutterBottom
                      >
                        demo@gmail.com
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }} mt={3}>
                      <LocationOnIcon sx={upperIconStyle} />
                      <Typography
                        variant="body1"
                        style={{ color: "white" }}
                        gutterBottom
                      >
                        132 Dartmouth Street Boston, Massachusetts 02156 United
                        States
                      </Typography>
                    </Box>
                  </InfoPaperBox>
                  <Box
                    mt={3}
                    display="flex"
                    justifyContent="space-between"
                    width="180px"
                  >
                    <Box sx={iconWrapperStyle}>
                      <FacebookIcon sx={iconStyle} />
                    </Box>
                    <Box sx={iconWrapperStyle}>
                      <TwitterIcon sx={iconStyle} />
                    </Box>
                    <Box sx={iconWrapperStyle}>
                      <LinkedInIcon sx={iconStyle} />
                    </Box>
                    <Box sx={iconWrapperStyle}>
                      <InstagramIcon sx={iconStyle} />
                    </Box>
                  </Box>
                </InfoPaper>
              </Grid>

              <Grid item xs={12} md={8}>
                <FormPaper elevation={3}>
                  <form style={{ flex: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.text.primary, mb: 1 }}
                        >
                          First name
                        </FormLabel>
                        <InputField
                          size="small"
                          placeholder="Enter your First name"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.text.primary, mb: 1 }}
                        >
                          Last name
                        </FormLabel>
                        <InputField
                          size="small"
                          placeholder="Enter your Last name"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.text.primary, mb: 1 }}
                        >
                          Email
                        </FormLabel>
                        <InputField
                          size="small"
                          placeholder="Enter your Email"
                          type="email"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.text.primary, mb: 1 }}
                        >
                          Phone number
                        </FormLabel>
                        <InputField
                          size="small"
                          placeholder="876-987-9876"
                          type="email"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl component="fieldset">
                          <FormLabel
                            component="legend"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "1.1rem",
                              color: theme.palette.text.primary,
                              "&.Mui-focused": {
                                color: theme.palette.text.primary,
                              },
                              "&:focus": {
                                outline: "none",
                              },
                            }}
                          >
                            Select Subject?
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="subject"
                            name="subject"
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <FormControlLabel
                              value="General Inquiry"
                              sx={{ color: theme.palette.text.primary }}
                              control={<CustomCheckBox />}
                              label="General Inquiry"
                            />
                            <FormControlLabel
                              value="General Inquiry1"
                              sx={{ color: theme.palette.text.primary }}
                              control={<CustomCheckBox />}
                              label="General Inquiry"
                            />
                            <FormControlLabel
                              value="General Inquiry2"
                              sx={{ color: theme.palette.text.primary }}
                              control={<CustomCheckBox />}
                              label="General Inquiry"
                            />
                            <FormControlLabel
                              value="General Inquiry3"
                              sx={{ color: theme.palette.text.primary }}
                              control={<CustomCheckBox />}
                              label="General Inquiry"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.text.primary, mb: 1 }}
                        >
                          Message
                        </FormLabel>
                        <InputField
                          placeholder="Write your Message.."
                          variant="outlined"
                          multiline
                          rows={4}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" sx={{ float: "right" }}>
                          Send Message
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </FormPaper>
              </Grid>
            </InnerContainer>
          </Grid>
        </Grid>
      </ContactContainer>
    </Layout>
  );
};

export default Contact;
