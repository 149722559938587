import React from "react";
import { MapContainer, TileLayer, Marker, Circle } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../theme/theme";
import { ArrowUpRight } from "../../../assets/svg/ArrowUpRight";

// Custom marker icon
const markerIcon = new L.Icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
  shadowSize: [41, 41],
});

// Light-themed map tiles
const tileUrl =
  "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png";

const LocationMap = (props) => {
  const position = [47.3769, 8.5417]; // Example coordinates (Zurich, Switzerland)
  const radius = 100; // Circle radius in meters

  return (
    <Box
      sx={{
        width: props.width ? props.width : "100%",
        pb: props.padding ? props.padding : 0,
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          display: props.title ? "flex" : "none",
        }}
        gutterBottom
      >
        {props.title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          gap: "24px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            height: "230px",
            border: `1px solid ${theme.palette.border.blue}`,
            borderRadius: "8px",
            overflow: "hidden",
            width: "100%",
            p: 2,
            [theme.breakpoints.down("md")]: {
              flexGrow: 1,
            },
          }}
        >
          <MapContainer
            center={position}
            zoom={15}
            style={{ width: "100%", height: "100%", borderRadius: "5px" }}
          >
            <TileLayer
              url={tileUrl}
              attribution='&copy; <a href="https://carto.com/attributions">Carto</a> contributors'
            />
            <Marker position={position} icon={markerIcon} />
            <Circle center={position} radius={radius} color="#4285F4" />
          </MapContainer>
        </Box>
        {!props.hideContact && (
          <Typography sx={{ whiteSpace: "nowrap" }}>
            Contact information
            <Typography
              sx={{
                display: "inline-flex",
                alignItems: "center",
                color: "#3E63DD",
                marginLeft: "4px",
              }}
            >
              here
              <ArrowUpRight />
            </Typography>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default LocationMap;
