import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Rating,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { testimonials } from "../../../store/slices/testimonialSlice";
import { useDispatch } from "react-redux";
import Loading from "../../Loading/Loading";
import { errorHandler } from "../../../utils";
import { theme } from "../../../theme/theme";
import { jsonData } from "../../../db";

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonial, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const itemsToShow = isSmallScreen ? 1 : isMediumScreen ? 3 : 2;
  const totalItems = jsonData.testimonials?.length;

  const startX = useRef(0);

  const dispatch = useDispatch();
  const getTestimonials = async () => {
    setIsLoading(true);
    try {
      const data = await dispatch(testimonials());
      if (
        data.payload.status === 200 &&
        data.meta.requestStatus === "fulfilled"
      ) {
        setTestimonials(data?.payload?.data);
      } else {
        console.log("No data found");
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error", error);
      errorHandler(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // getTestimonials();
  }, []);

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    const deltaX = startX.current - endX;

    if (Math.abs(deltaX) > 50) {
      if (deltaX > 0) {
        handleNext();
      } else {
        handlePrev();
      }
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = Math.min(
        prevIndex + itemsToShow,
        totalItems - itemsToShow
      );
      return newIndex;
    });
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - itemsToShow, 0));
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index * itemsToShow);
  };

  return (
    <>
      {!isLoading ? (
        <Box
          sx={{
            padding: "60px 0 80px",
            backgroundColor: theme.palette.background.lightBlue,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            fontWeight={700}
            fontSize={"35px"}
            align="center"
            gutterBottom
          >
            Testimonials
          </Typography>

          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
              pl: 5,
            }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <Box
              sx={{
                display: "flex",
                transition: "transform 0.5s ease",
                transform: `translateX(-${(currentIndex * 100) / totalItems}%)`,
                width: `${(totalItems * 100) / itemsToShow}%`,
              }}
            >
              {jsonData.testimonials?.map((testimonial, index) => (
                <Card
                  key={index}
                  sx={{
                    display: "flex",
                    margin: "10px",
                    padding: "10px",
                    borderRadius: "10px",
                    border: "1px solid #d7e1ff",
                    boxShadow: "none",
                    width: "300px",
                    height: "auto",
                    textAlign: "left",
                    flex: 1,
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "76px",
                    }}
                  >
                    <Box>
                      <Rating value={testimonial.rating} readOnly />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "black",
                        }}
                        mt={2}
                        variant="body1"
                        gutterBottom
                      >
                        {testimonial.text}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "left",
                        marginTop: "30px",
                      }}
                    >
                      <Avatar
                        src={testimonial.avatar}
                        sx={{ marginRight: "10px" }}
                      />
                      <Box>
                        <Typography variant="subtitle1">
                          {testimonial.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {testimonial.location}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {testimonial.details}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>

          {/* Dots Navigation */}
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Box>
              <IconButton
                onClick={handlePrev}
                sx={{
                  color: "#929293",
                  border: "1px solid #929293",
                  height: "30px",
                  width: "30px",
                }}
              >
                <ArrowBackIcon
                  sx={{
                    fontSize: "16px",
                  }}
                />
              </IconButton>
            </Box>
            <Box>
              {Array.from({ length: Math.ceil(totalItems / itemsToShow) }).map(
                (_, index) => (
                  <IconButton
                    key={index}
                    onClick={() => handleDotClick(index)}
                    sx={{
                      padding: "5px",
                      color:
                        Math.floor(currentIndex / itemsToShow) === index
                          ? "#5f698c"
                          : "#ccc",
                    }}
                  >
                    <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                  </IconButton>
                )
              )}
            </Box>
            <Box>
              <IconButton
                onClick={handleNext}
                sx={{
                  color: "#929293",
                  border: "1px solid #929293",
                  height: "30px",
                  width: "30px",
                }}
              >
                <ArrowForwardIcon
                  sx={{
                    fontSize: "16px",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default TestimonialCarousel;
