import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import authService from "../../services/authService";

const initialState = {
  properties: {
    data: [],
    isLoading: false,
    error: null,
  },
  createProperty: {
    data: null,
    isLoading: false,
    error: null,
  },
  currentProperty: {
    data: null,
    isLoading: false,
    error: null,
  },
  updateProperty: {
    data: null,
    isLoading: false,
    error: null,
  },
  deleteProperty: {
    data: null,
    isLoading: false,
    error: null,
  },
};

const API_URL = process.env.REACT_APP_BASE_URL;

export const fetchProperties = createAsyncThunk(
  "properties/fetchProperties",
  async (_, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.get(`${API_URL}/property/`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createProperty = createAsyncThunk(
  "properties/create-property",
  async (body, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.post(`${API_URL}/property/`, body, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getProperty = createAsyncThunk(
  "properties/get-property",
  async (id, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.get(`${API_URL}/property/${id}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateProperty = createAsyncThunk(
  "properties/update-property",
  async ({ id, body }, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.put(`${API_URL}/property/${id}`, body, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteProperty = createAsyncThunk(
  "properties/delete-property",
  async (id, thunkAPI) => {
    try {
      const user = authService.getCurrentUser();
      const response = await axios.delete(`${API_URL}/property/${id}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProperties.pending, (state) => {
        state.properties.isLoading = true;
        state.properties.error = null;
      })
      .addCase(fetchProperties.fulfilled, (state, action) => {
        state.properties.isLoading = false;
        state.properties.data = action.payload;
      })
      .addCase(fetchProperties.rejected, (state, action) => {
        state.properties.isLoading = false;
        state.properties.error = action.payload;
      })

      .addCase(createProperty.pending, (state) => {
        state.createProperty.isLoading = true;
        state.createProperty.error = null;
      })
      .addCase(createProperty.fulfilled, (state, action) => {
        state.createProperty.isLoading = false;
        state.createProperty.data = action.payload;
      })
      .addCase(createProperty.rejected, (state, action) => {
        state.createProperty.isLoading = false;
        state.createProperty.error = action.payload;
      })

      .addCase(getProperty.pending, (state) => {
        state.currentProperty.isLoading = true;
        state.currentProperty.error = null;
      })
      .addCase(getProperty.fulfilled, (state, action) => {
        state.currentProperty.isLoading = false;
        state.currentProperty.data = action.payload;
      })
      .addCase(getProperty.rejected, (state, action) => {
        state.currentProperty.isLoading = false;
        state.currentProperty.error = action.payload;
      })

      .addCase(updateProperty.pending, (state) => {
        state.updateProperty.isLoading = true;
        state.updateProperty.error = null;
      })
      .addCase(updateProperty.fulfilled, (state, action) => {
        state.updateProperty.isLoading = false;
        state.updateProperty.data = action.payload;
      })
      .addCase(updateProperty.rejected, (state, action) => {
        state.updateProperty.isLoading = false;
        state.updateProperty.error = action.payload;
      })

      .addCase(deleteProperty.pending, (state) => {
        state.deleteProperty.isLoading = true;
        state.deleteProperty.error = null;
      })
      .addCase(deleteProperty.fulfilled, (state, action) => {
        state.deleteProperty.isLoading = false;
        state.deleteProperty.data = action.payload;
      })
      .addCase(deleteProperty.rejected, (state, action) => {
        state.deleteProperty.isLoading = false;
        state.deleteProperty.error = action.payload;
      });
  },
});

export const selectProperties = (state) => state.properties;

export default propertiesSlice.reducer;
