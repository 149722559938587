export const jsonData = {
  counter: {
    count: "1242",
  },
  testimonials: [
    {
      id: 1,
      name: "Alice B.",
      location: "Bahnhofstrasse 10, 8001 Zürich",
      details: "4 Bed / 3 Ba • ₣3,200 per month",
      avatar:
        "https://images.unsplash.com/photo-1519046904884-53103b34b206?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      rating: 5,
      text: "I was able to find the perfect home in no time. The service was exceptional and so easy to use.",
    },
    {
      id: 2,
      name: "Michael K.",
      location: "Rämistrasse 28, 8001 Zürich",
      details: "2 Bed / 2 Ba • ₣2,200 per month",
      avatar: "https://unsplash.com/photos/J7w6Hk7lbXk",
      rating: 4,
      text: "Very efficient and user-friendly. Found a great place that fits my needs perfectly.",
    },
    {
      id: 3,
      name: "Olivia H.",
      location: "Limmatquai 70, 8001 Zürich",
      details: "3 Bed / 2 Ba • ₣2,800 per month",
      avatar: "https://unsplash.com/photos/0Z3rFvDhs5k",
      rating: 5,
      text: "A fantastic experience! The AI-driven search made everything so simple and stress-free.",
    },
    {
      id: 4,
      name: "David G.",
      location: "Seefeldstrasse 123, 8008 Zürich",
      details: "1 Bed / 1 Ba • ₣1,500 per month",
      avatar: "https://unsplash.com/photos/mEZ3PoFGs_k",
      rating: 3,
      text: "Good service, though I had some minor issues with the search filters. Overall, a positive experience.",
    },
    {
      id: 5,
      name: "Sophia M.",
      location: "Niederdorfstrasse 66, 8001 Zürich",
      details: "3 Bed / 1 Ba • ₣2,400 per month",
      avatar: "https://unsplash.com/photos/iFgRcqHznqg",
      rating: 4,
      text: "Easy to use and reliable. I found a great apartment that I love.",
    },
    {
      id: 6,
      name: "Liam T.",
      location: "Zurichbergstrasse 112, 8044 Zürich",
      details: "4 Bed / 2 Ba • ₣3,000 per month",
      avatar: "https://unsplash.com/photos/SJzHyhJFi8I",
      rating: 5,
      text: "This service saved me so much time. Highly recommended for anyone looking for a new home.",
    },
    {
      id: 7,
      name: "Chloe F.",
      location: "Langstrasse 50, 8004 Zürich",
      details: "2 Bed / 1 Ba • ₣1,900 per month",
      avatar: "https://unsplash.com/photos/RrhhzitYizg",
      rating: 4,
      text: "Smooth process from start to finish. The insights were spot-on.",
    },
    {
      id: 8,
      name: "James R.",
      location: "Sihlquai 10, 8005 Zürich",
      details: "3 Bed / 2 Ba • ₣2,600 per month",
      avatar: "https://unsplash.com/photos/UWcP02uAXJ4",
      rating: 5,
      text: "The best way to find a home. Simple, efficient, and accurate.",
    },
    {
      id: 9,
      name: "Isabella N.",
      location: "Europaallee 21, 8004 Zürich",
      details: "1 Bed / 1 Ba • ₣1,700 per month",
      avatar: "https://unsplash.com/photos/RN6ts8IZ4_0",
      rating: 4,
      text: "I had a great experience. Found a nice place quickly and without any hassle.",
    },
  ],
  differences: [
    {
      title: "Search Frequency",
      description: "Ranges from daily (Free) to real-time (Pro and VIP).",
    },
    {
      title: "Search Scope:",
      description:
        "Limited to 1 match per month in Free, 10 in Premium, 30 in Pro, and unlimited in VIP.",
    },
    {
      title: "Data Analysis:",
      description:
        "Text-only in Free, basic photo analysis in Premium, and in-depth analysis with environmental and market data in Pro and VIP.",
    },
    {
      title: "Support:",
      description:
        "Community-based in Free, email support in Premium, dedicated account managers in Pro, and priority service in VIP",
    },
    {
      title: "Exclusive Extras:",
      description:
        "The VIP Plan offers automated application submissions and real-time communication via WhatsApp.",
    },
  ],
  plan: [
    {
      id: 1,
      title: "Free: Essential Search",
      price: "Free",
      description:
        "Casual renters, budget-conscious individuals, or first-time users exploring  AI capabilities",
      features: [
        {
          title: "Basic Property Matching",
          description:
            "The AI matches properties based on essential criteria such as budget, location, commute time to work and family members, and property size.",
        },
        {
          title: "Daily Market Scan",
          description: "",
        },
        {
          title: "Limited Search Scope",
          description: "",
        },
        {
          title: "Text-Based Analysis",
          description: "",
        },
        {
          title: "Community Support",
          description: "",
        },
      ],
      advantages: [
        {
          title: "Free with no financial commitment.",
        },
        {
          title:
            "Ideal for users with simple needs or just starting their property search.",
        },
        {
          title: "Experience AI-driven property search at no cost.",
        },
      ],
      buttonText: "Get Started",
      selected: false,
    },
    {
      id: 2,
      title: "Premium: Advanced Search",
      price: "₣49.99",
      description:
        "Renters with specific needs who require more detailed search capabilities.",
      features: [
        {
          title: "Enhanced Property Matching",
          description: "",
        },
        {
          title: "Frequent Market Scan",
          description: "",
        },
        {
          title: "Broader Search Scope",
          description: "",
        },
        {
          title: "Automated Alerts",
          description: "",
        },
        {
          title: "Text and Basic Photo Analysis",
          description: "",
        },
        {
          title: "Personalized Support",
          description: "",
        },
      ],
      advantages: [
        {
          title: "Faster access to new listings with frequent scans.",
        },
        {
          title: "More detailed insights and control over property features.",
        },
        {
          title:
            "Ideal for renters who need more than just basic search capabilities.",
        },
      ],
      buttonText: "Get Started",
      selected: true,
    },
    {
      id: 3,
      title: "Pro: Comprehensive Search",
      price: "₣99.99",
      description:
        "High-demand renters, professionals, or families needing a highly personalized and proactive property search experience.",
      features: [
        {
          title: "Ultimate Property Matching",
        },
        {
          title: "Real-Time Market Scan",
        },
        {
          title: "Expanded Search Scope",
        },
        {
          title: "In-Depth Analysis",
        },
        {
          title: "VIP Support",
        },
        {
          title: "WhatsApp Notifications",
        },
      ],
      advantages: [
        {
          title: "The most comprehensive property search experience available.",
        },
        {
          title:
            "Perfect for users with specific needs in competitive markets.",
        },
        {
          title:
            "Highly personalized insights and real-time updates via WhatsApp and email.",
        },
      ],
      buttonText: "Get Started",
      selected: false,
    },
    {
      id: 4,
      title: "VIP: Elite Search",
      price: "₣199.99",
      description:
        "Renters who want an all-inclusive, hands-off search experience with top-tier features.",
      features: [
        {
          title: "All Pro Plan Features",
        },
        {
          title: "Success potential",
        },
        {
          title: "Instant Application Submission",
        },
        {
          title: "WhatsApp Notifications",
        },
        {
          title: "Datasets Used",
        },
      ],
      advantages: [
        {
          title: "Complete convenience with automated application submissions.",
        },
        {
          title: "Real-time updates and communication via WhatsApp.",
        },
        {
          title:
            "Ideal for renters who prioritize speed, efficiency, and a hands-off approach.",
        },
      ],
      buttonText: "Get Started",
      selected: false,
    },
  ],
  shortPlan: [
    {
      id: 1,
      title: "Free",
      price: "Free",
      description: "Basic plan for essential needs",
      features: [
        { title: "Limited property matches" },
        { title: "Basic AI assistance" },
      ],
      advantages: [],
      buttonText: "Get Started",
      selected: false,
    },
    {
      id: 2,
      title: "Premium",
      price: "₣49",
      description: "Advanced features for serious searchers",
      features: [
        { title: "Unlimited matches" },
        { title: "Advanced AI features" },
        { title: "Priority support" },
      ],
      advantages: [],
      buttonText: "Get Started",
      selected: true,
    },
    {
      id: 3,
      title: "Pro",
      price: "₣79",
      description: "Comprehensive solution for professionals",
      features: [
        { title: "Full AI capabilities" },
        { title: "Personalized market analysis" },
        { title: "24/7 dedicated support" },
      ],
      advantages: [],
      buttonText: "Get Started",
      selected: false,
    },
    {
      id: 4,
      title: "VIP",
      price: "₣99",
      description: "Elite service for discerning clients",
      features: [
        { title: "Full AI capabilities" },
        { title: "Personalized market analysis" },
        { title: "24/7 dedicated support" },
      ],
      advantages: [],
      buttonText: "Get Started",
      selected: false,
    },
  ],
  team: [
    {
      id: 1,
      alt: "img",
      src: "https://images.unsplash.com/photo-1585580829850-a074625a2f27?q=80&w=2011&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 2,
      alt: "img",
      src: "https://images.unsplash.com/photo-1585580829850-a074625a2f27?q=80&w=2011&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 3,
      alt: "img",
      src: "https://images.unsplash.com/photo-1585580829850-a074625a2f27?q=80&w=2011&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 4,
      alt: "img",
      src: "https://images.unsplash.com/photo-1585580829850-a074625a2f27?q=80&w=2011&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 5,
      alt: "img",
      src: "https://images.unsplash.com/photo-1585580829850-a074625a2f27?q=80&w=2011&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 6,
      alt: "img",
      src: "https://images.unsplash.com/photo-1585580829850-a074625a2f27?q=80&w=2011&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  ],
  stepper: [
    {
      id: 1,
      label: "Location Preferences",
    },
    {
      id: 2,
      label: "Budget and Financial Consideration",
    },
    {
      id: 3,
      label: "Apartment Features and Amenities",
    },
    {
      id: 4,
      label: "Lifestyle and Personal Preferences",
    },
    {
      id: 5,
      label: "Move-in Readiness and Timing",
    },
  ],
  questions: {
    steps: [
      {
        id: 1,
        label: "Location Preferences",
        questions: [
          {
            id: 1,
            question: "What city or neighborhood are you interested in?",
            type: "text",
            required: true,
            nextQuestion: 2,
          },
          {
            id: 2,
            question:
              "How close would you like your home to be from your workplace or school?",
            type: "text",
            required: true,
            nextQuestion: 3,
          },
          {
            id: 3,
            question: "Do you need easy access to public transport?",
            type: "boolean",
            required: false,
            nextQuestion: 4,
          },
          {
            id: 4,
            question: "How important is the safety of the neighborhood to you?",
            type: "scale",
            scaleMin: 1,
            scaleMax: 5,
            required: true,
            nextQuestion: 5,
          },
          {
            id: 5,
            question: "What types of amenities would you like nearby?",
            type: "text",
            required: false,
            nextQuestion: null,
          },
        ],
      },
      {
        id: 2,
        label: "Budget and Financial Considerations",
        questions: [
          {
            id: 6,
            question:
              "What is your maximum monthly budget for rent or mortgage?",
            type: "number",
            required: true,
            nextQuestion: 7,
          },
          {
            id: 7,
            question:
              "If buying, how much can you afford for the down payment?",
            type: "number",
            required: false,
            nextQuestion: 8,
          },
          {
            id: 8,
            question:
              "Are there any other financial considerations we should know about?",
            type: "text",
            required: false,
            nextQuestion: null,
          },
        ],
      },
      {
        id: 3,
        label: "Apartment Features and Amenities",
        questions: [
          {
            id: 9,
            question:
              "What type of apartment are you looking for? (e.g., studio, 1-bedroom, 2-bedroom)",
            type: "text",
            required: true,
            nextQuestion: 10,
          },
          {
            id: 10,
            question:
              "Do you have any preferences for apartment amenities? (e.g., pool, gym, parking)",
            type: "text",
            required: false,
            nextQuestion: 11,
          },
          {
            id: 11,
            question: "What is the minimum number of bedrooms you need?",
            type: "number",
            required: true,
            nextQuestion: 12,
          },
          {
            id: 12,
            question:
              "Do you need any specific features in the apartment? (e.g., balcony, fireplace)",
            type: "text",
            required: false,
            nextQuestion: null,
          },
        ],
      },
      {
        id: 4,
        label: "Lifestyle and Personal Preferences",
        questions: [
          {
            id: 13,
            question: "Do you prefer a quiet or lively neighborhood?",
            type: "text",
            required: true,
            nextQuestion: 14,
          },
          {
            id: 14,
            question: "Do you have any pets or plan to get any in the future?",
            type: "boolean",
            required: false,
            nextQuestion: 15,
          },
          {
            id: 15,
            question:
              "Are there any hobbies or activities you want to be close to? (e.g., hiking, dining)",
            type: "text",
            required: false,
            nextQuestion: null,
          },
        ],
      },
      {
        id: 5,
        label: "Move-in Readiness and Timing",
        questions: [
          {
            id: 16,
            question: "When do you plan to move in?",
            type: "date",
            required: true,
            nextQuestion: 17,
          },
          {
            id: 17,
            question:
              "Are you looking for a home that is move-in ready or are you open to renovations?",
            type: "text",
            required: false,
            nextQuestion: 18,
          },
          {
            id: 18,
            question:
              "Do you need to coordinate with your current lease end date?",
            type: "boolean",
            required: false,
            nextQuestion: null,
          },
        ],
      },
    ],
  },
  insights: {
    "Location Preferences": [
      "Close to Johar Town",
      "Central vibrant areas",
      "Public transport essentials",
    ],
    "Budget and financial consideration": [
      "20k-30k",
      "Standard deposit",
      "Long term stability",
    ],
  },
  blogPosts: [
    {
      id: 1,
      title: "The Impact of Rising Interest Rates on Housing Affordability",
      category: "Trends",
      image:
        "https://images.unsplash.com/photo-1605146769289-440113cc3d00?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      subTitle:
        "Managing rental properties can be a rewarding investment, but it also comes with responsibilities. Here are some essential tips and best practices to help you effectively manage your rental properties:",
      contentList: [
        {
          title: "1. Thorough Tenant Screening",
          text: "Background Checks: Conduct thorough background checks to verify potential tenants' rental history, creditworthiness, and criminal records. Income Verification: Ensure tenants have a stable income that can cover the monthly rent. References: Contact previous landlords or references to assess tenants' character and reliability.",
        },
        {
          title: "2. Clear Lease Agreement",
          text: "Detailed Terms: Create a comprehensive lease agreement that outlines the tenant's rights and responsibilities, rent amount, security deposit, maintenance obligations, and termination clauses. Legal Consultation: Consult with a legal professional to ensure your lease agreement complies with local laws and regulations.",
        },
        {
          title: "3. Routine Property Inspection",
          text: "Regular Checks: Conduct regular property inspections to monitor the condition of the property and address any maintenance issues promptly. Tenant Notification: Notify tenants in advance of inspections and provide reasonable access.",
        },
        {
          title: "4. Effective Communication",
          text: "Open Dialogue: Maintain open communication with tenants to address concerns, resolve disputes, and ensure a positive tenant-landlord relationship. Prompt Responses: Respond to tenant inquiries and maintenance requests in a timely manner.",
        },
        {
          title: "5. Property Maintenance",
          text: "Regular Upkeep: Address maintenance issues promptly to prevent larger problems and maintain property value. Emergency Plan: Have a plan in place for handling emergency situations like plumbing leaks, electrical outages, or tenant emergencies.",
        },
        // Add the rest of the items here...
      ],
    },
    {
      id: 2,
      title: "Managing Rental Properties: Tips and Best Practices",
      category: "Trends",
      image:
        "https://images.unsplash.com/photo-1494526585095-c41746248156?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 3,
      title: "Understanding Different Types of Mortgages",
      category: "Buying",
      image:
        "https://images.unsplash.com/photo-1558036117-15d82a90b9b1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 4,
      title: "The Pros and Cons of Owning a Rental Property",
      category: "Trends",
      image:
        "https://images.unsplash.com/photo-1444676632488-26a136c45b9b?q=80&w=1937&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 5,
      title: "How to Stage a Home for a Quick Sale",
      category: "Selling",
      image:
        "https://images.unsplash.com/photo-1512915922686-57c11dde9b6b?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 6,
      title: "Maximizing Space: Design Tips for Small Homes",
      category: "Design",
      image:
        "https://images.unsplash.com/photo-1560185008-b033106af5c3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 7,
      title: "Essential Steps for First-Time Homebuyers",
      category: "Buying",
      image:
        "https://images.unsplash.com/photo-1560185127-2d06c6d08d3d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 8,
      title: "The Future of Real Estate Investment in Urban Areas",
      category: "Investment",
      image:
        "https://images.unsplash.com/photo-1560185009-dddeb820c7b7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 9,
      title: "The Benefits of Sustainable Home Design",
      category: "Design",
      image:
        "https://plus.unsplash.com/premium_photo-1686090449200-57266c6623a6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 10,
      title: "Understanding Property Taxes for Rental Properties",
      category: "Rental",
      image:
        "https://images.unsplash.com/photo-1605276374104-dee2a0ed3cd6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 11,
      title: "Navigating the Real Estate Market in 2024",
      category: "Trends",
      image:
        "https://images.unsplash.com/photo-1605146768851-eda79da39897?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 12,
      title: "How to Finance a Home Renovation Project",
      category: "Investment",
      image:
        "https://images.unsplash.com/photo-1570129477492-45c003edd2be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 13,
      title: "The Importance of Location in Real Estate Investments",
      category: "Investment",
      image:
        "https://images.unsplash.com/photo-1592595896551-12b371d546d5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 14,
      title: "What to Look for in a Vacation Rental Property",
      category: "Rental",
      image:
        "https://images.unsplash.com/photo-1565402170291-8491f14678db?q=80&w=2017&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 15,
      title: "Top Trends in Modern Home Architecture",
      category: "Design",
      image:
        "https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  ],
  currentPlan: {
    name: "Free: Essential Search",
    price: "Free",
    keyFeatures: [],
  },
  upgradePlan: {
    name: "Premium Advance Search",
    price: "£49.99",
    keyFeatures: [
      {
        feature: "Enhanced Property Matching",
        description: "Taxes, other interests like hobbies etc.",
      },
      {
        feature: "Frequent Market Scan",
        description: "Regular updates on market trends.",
      },
      {
        feature: "Broader Search Scope",
        description: "Wider range of properties and data.",
      },
      {
        feature: "Automated Alerts",
        description: "Notifications for new properties.",
      },
      {
        feature: "Text and Basic Photo Analysis",
        description: "AI-based property analysis.",
      },
      {
        feature: "Personalized Support",
        description: "Dedicated customer support.",
      },
    ],
  },
  discountOffer: {
    upgradePlanName: "Pro: Comprehensive Search",
    timer: "11:11",
  },
  proPlan: {
    name: "Pro: Comprehensive Search",
    price: "£79.99",
    originalPrice: "£99.99",
    keyFeatures: [
      { feature: "Ultimate Property Matching", description: "desc" },
      {
        feature: "Real-Time Market Scan",
        description: "Continuous market scans with instant updates.",
      },
      { feature: "Expanded Search Scope", description: "desc" },
      { feature: "In-Depth Analysis", description: "desc" },
      { feature: "VIP Support", description: "desc" },
      { feature: "WhatsApp Notifications", description: "desc" },
    ],
  },
  profile: {
    name: "typicode",
  },
  chat: [
    {
      id: "1",
      name: "",
    },
  ],
  accountNotes: [
    {
      image:
        "https://plus.unsplash.com/premium_photo-1723901831135-782c98d8d8e0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      address: "Johar Town 345 Lahore,Pakistan",
      beds: 3,
      baths: 3,
      sqrFeet: 1720,
      price: "£79.99",
      notes: 4,
      feedbacks: [
        {
          name: "Amanda Bush",
          image: "https://via.placeholder.com/100",
          date: "Apr 11, 2024",
          description:
            "Thank a lot for your help with this apartment. I’m really happy about it!",
        },
        {
          name: "Amanda Bush",
          image: "https://via.placeholder.com/100",
          date: "Apr 11, 2024",
          description:
            "Thank a lot for your help with this apartment. I’m really happy about it!",
        },
        {
          name: "Amanda Bush",
          image: "https://via.placeholder.com/100",
          date: "Apr 11, 2024",
          description:
            "Thank a lot for your help with this apartment. I’m really happy about it!",
        },
        {
          name: "Amanda Bush",
          image: "https://via.placeholder.com/100",
          date: "Apr 11, 2024",
          description:
            "Thank a lot for your help with this apartment. I’m really happy about it!",
        },
      ],
    },
    {
      image:
        "https://images.unsplash.com/photo-1592595896551-12b371d546d5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      address: "Johar Town 345 Lahore,Pakistan",
      beds: 3,
      baths: 2,
      sqrFeet: 1710,
      price: "£89.99",
      notes: 3,
      feedbacks: [
        {
          name: "Amanda Bush",
          image: "https://via.placeholder.com/100",
          date: "Apr 11, 2024",
          description:
            "Thank a lot for your help with this apartment. I’m really happy about it!",
        },
        {
          name: "Amanda Bush",
          image: "https://via.placeholder.com/100",
          date: "Apr 11, 2024",
          description:
            "Thank a lot for your help with this apartment. I’m really happy about it!",
        },
        {
          name: "Amanda Bush",
          image: "https://via.placeholder.com/100",
          date: "Apr 11, 2024",
          description:
            "Thank a lot for your help with this apartment. I’m really happy about it!",
        },
      ],
    },
    {
      image:
        "https://images.unsplash.com/photo-1560185127-2d06c6d08d3d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      address: "Johar Town 345 Lahore,Pakistan",
      beds: 2,
      baths: 3,
      sqrFeet: 1420,
      price: "£99.99",
      notes: 1,
      feedbacks: [
        {
          name: "Amanda Bush",
          image: "https://via.placeholder.com/100",
          date: "Apr 11, 2024",
          description:
            "Thank a lot for your help with this apartment. I’m really happy about it!",
        },
        {
          name: "Amanda Bush",
          image: "https://via.placeholder.com/100",
          date: "Apr 11, 2024",
          description:
            "Thank a lot for your help with this apartment. I’m really happy about it!",
        },
        {
          name: "Amanda Bush",
          image: "https://via.placeholder.com/100",
          date: "Apr 11, 2024",
          description:
            "Thank a lot for your help with this apartment. I’m really happy about it!",
        },
        {
          name: "Amanda Bush",
          image: "https://via.placeholder.com/100",
          date: "Apr 11, 2024",
          description:
            "Thank a lot for your help with this apartment. I’m really happy about it!",
        },
      ],
    },
  ],
  cancelSubscriptionReasons: [
    "Only want to pay monthly",
    "HOME AI sufficient for my needs",
    "Premium is too expensive",
    "Premium features are not valuable to me",
    "Product has too many bugs",
    "No Longer Need",
    "Don't use diagramming tools enough to pay",
    "Don't know the Home AI Search",
    "Other",
  ],
  accountContacts: [
    {
      id: 1,
      name: "Annet Wilson",
      lastMessage: "Hello!",
      date: "02 Aug",
      unreadCount: 2,
      messages: [
        {
          id: 1,
          content: "Hello!",
          sender: "Christopher Blake",
          time: "10:01 AM",
        },
        {
          id: 2,
          content:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
          sender: "Christopher Blake",
          time: "10:01 AM",
        },
        {
          id: 3,
          content:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using",
          sender: "You",
          time: "10:01 AM",
        },
      ],
    },
    {
      id: 2,
      name: "Annet Wilson",
      lastMessage: "Hello!",
      date: "02 Aug",
      unreadCount: 2,
      messages: [
        {
          id: 1,
          content: "Hello!",
          sender: "Christopher Blake",
          time: "10:01 AM",
        },
        {
          id: 2,
          content:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
          sender: "Christopher Blake",
          time: "10:01 AM",
        },
        {
          id: 3,
          content:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using",
          sender: "You",
          time: "10:01 AM",
        },
        {
          id: 4,
          content:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
          sender: "You",
          time: "10:01 AM",
        },
      ],
    },
    {
      id: 3,
      name: "Annet Wilson",
      lastMessage: "Hello!",
      date: "02 Aug",
      unreadCount: 2,
      messages: [
        {
          id: 1,
          content: "Hello!",
          sender: "Christopher Blake",
          time: "10:01 AM",
        },
        {
          id: 2,
          content:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
          sender: "Christopher Blake",
          time: "10:01 AM",
        },
        {
          id: 3,
          content:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using",
          sender: "You",
          time: "10:01 AM",
        },
      ],
    },
    {
      id: 4,
      name: "Annet Wilson",
      lastMessage: "Hello!",
      date: "02 Aug",
      unreadCount: 2,
      messages: [
        {
          id: 1,
          content: "Hello!",
          sender: "Christopher Blake",
          time: "10:01 AM",
        },
        {
          id: 2,
          content:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
          sender: "Christopher Blake",
          time: "10:01 AM",
        },
        {
          id: 3,
          content:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using",
          sender: "You",
          time: "10:01 AM",
        },
      ],
    },
    {
      id: 5,
      name: "Annet Wilson",
      lastMessage: "Hello!",
      date: "02 Aug",
      unreadCount: 2,
      messages: [
        {
          id: 1,
          content: "Hello!",
          sender: "Christopher Blake",
          time: "10:01 AM",
        },
        {
          id: 2,
          content:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
          sender: "Christopher Blake",
          time: "10:01 AM",
        },
        {
          id: 3,
          content:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using",
          sender: "You",
          time: "10:01 AM",
        },
      ],
    },
    {
      id: 6,
      name: "Annet Wilson",
      lastMessage: "Hello!",
      date: "02 Aug",
      unreadCount: 2,
      messages: [
        {
          id: 1,
          content: "Hello!",
          sender: "Christopher Blake",
          time: "10:01 AM",
        },
        {
          id: 2,
          content:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.",
          sender: "Christopher Blake",
          time: "10:01 AM",
        },
        {
          id: 3,
          content:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using",
          sender: "You",
          time: "10:01 AM",
        },
      ],
    },
  ],
};
