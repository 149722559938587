import { Box, Typography, Button } from "@mui/material";
import React from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { theme } from "../../theme/theme";
import { jsonData } from "../../db";

const Insights = ({ insights, adjust, emptyInsights, title, icon }) => {
  const buttonStyling = {
    width: "100%",
    backgroundColor: "transparent",
    color: "#1C2024",
    border: `1px solid #0007149F`,
    height: "40px",
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        px: 2,
        flexDirection: "column", // Ensure items are stacked vertically
      }}
    >
      <Box sx={{ pr: 0.5, pt: 3 }}>
        <Typography sx={{ pb: 2, color: "black", fontWeight: 600 }}>
          {title}
        </Typography>
      </Box>

      {jsonData?.insights && !emptyInsights ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {Object.keys(jsonData.insights).map((key) => (
            <Box key={key} sx={{ mb: 3 }}>
              <Typography sx={{ fontWeight: 500, color: "black", mb: 1 }}>
                {key}:
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {jsonData.insights[key].map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: theme.palette.background.lightBlue,
                      color: "#4f6ece",
                      border: "1px solid #4f6ece",
                      borderRadius: "5px",
                      padding: "4px 8px",
                      fontSize: "14px",
                    }}
                  >
                    {item}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
          {adjust && (
            <Button fullWidth sx={buttonStyling}>
              Adjust parameters
            </Button>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {icon || (
            <AutoAwesomeIcon
              sx={{
                backgroundColor: "#edf2fe",
                color: "#8da4ef",
                height: "30px",
                width: "30px",
                borderRadius: "100%",
                p: 1,
              }}
            />
          )}
          <Typography
            sx={{
              fontWeight: 500,
              color: "black",
              py: 1.5,
            }}
          >
            No insights
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
            }}
          >
            Here I will show your priorities based on your answers to help you
            understand them better and find the perfect apartment.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Insights;
