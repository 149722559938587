export const CaretDown = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill={fill} fill-opacity="0.01" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.4609 6.59395C4.64836 6.40651 4.95228 6.40651 5.13973 6.59395L8.00032 9.45455L10.8609 6.59395C11.0483 6.40651 11.3522 6.40651 11.5397 6.59395C11.7272 6.78141 11.7272 7.08532 11.5397 7.27278L8.33973 10.4728C8.2497 10.5628 8.12762 10.6134 8.00032 10.6134C7.87301 10.6134 7.75092 10.5628 7.6609 10.4728L4.4609 7.27278C4.27345 7.08532 4.27345 6.78141 4.4609 6.59395Z"
      fill={fill}
    />
  </svg>
);
