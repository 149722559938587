import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import Loading from "../../Loading/Loading";
import FeatureCard from "./FeatureCard";
import { jsonData } from "../../../db";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const StyledCard = styled(Card)(({ theme }) => ({
  textAlign: "left",
  borderRadius: "5px",
  padding: theme.spacing(2),
  border: "1px solid #d7e1ff",
  position: "relative",
}));

const PriceTag = styled(Typography)(({ theme, selected }) => ({
  color: selected ? "#fff" : theme.palette.text.primary,
  fontSize: "2rem",
  fontWeight: "bold",
}));

const FeatureList = styled("ul")({
  display: "flex",
  flexDirection: "column",
  listStyle: "none",
  padding: 0,
  marginTop: "1rem",
  gap: 15,
});

const checkIconStyle = {
  marginRight: 0.5,
  color: "#4CAF50",
  fontSize: "16px",
  borderRadius: "100%",
};

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "inherit",
  display: "block",
  height: "100%",
});

const PricingPlans = ({ isPricing }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // getPlans();
  }, []);
  const planData = isPricing ? jsonData.plan : jsonData.shortPlan;
  return (
    <Container>
      {!isLoading ? (
        <Box id="pricing" sx={{ padding: "2rem", px: 6 }}>
          <Typography
            variant="h5"
            fontWeight={700}
            fontSize={"35px"}
            align="center"
            gutterBottom
            pt={5}
            pb={isPricing ? 0 : 5}
          >
            Monthly Subscription Plans for <br /> AI-Driven Property Search
          </Typography>
          {isPricing && (
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "14px", color: "#6B7280", pb: "40px" }}
              align="center"
              gutterBottom
            >
              To cater to different renter needs, we offer a range of
              subscription plans from a <br /> free option to a top-tier VIP
              plan that provides the ultimate convenience and features.
            </Typography>
          )}
          <Grid container spacing={4} justifyContent="center">
            {planData.map((plan, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <StyledLink to={isPricing ? `/payment/${plan.id}` : `/plan`}>
                  <StyledCard
                    sx={{
                      backgroundColor: plan.selected ? "#3a5bc7" : "#e1e9ff",
                      color: plan.selected ? "#fff" : "#000",
                      boxShadow: "none",
                      minHeight: isPricing ? "922px" : "575px",
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          height: "220px",
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          {plan.title}
                        </Typography>
                        <PriceTag selected={plan.selected}>
                          {plan.price}
                        </PriceTag>
                        <Typography
                          sx={{
                            py: 3,
                            color: plan.selected && "white",
                          }}
                        >
                          Per Month
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: plan.selected && "white",
                          }}
                        >
                          {plan.description}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          height: "1px",
                          backgroundColor: plan.selected
                            ? "#ffffff33"
                            : "#20202033",
                        }}
                      />
                      <Box
                        sx={{
                          pt: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            py: 3,
                            pt: 1,
                            pb: "8px",
                            color: plan.selected && "white",
                          }}
                        >
                          Key Features
                        </Typography>
                      </Box>
                      <FeatureList>
                        {plan.features.map((feature, idx) => (
                          <FeatureCard
                            key={idx}
                            feature={feature}
                            selected={plan.selected}
                          />
                        ))}
                      </FeatureList>
                      {plan.advantages.length > 0 && (
                        <>
                          <Box
                            sx={{
                              width: "100%",
                              height: "1px",
                              marginTop: "28px",
                              backgroundColor: plan.selected
                                ? "#ffffff33"
                                : "#20202033",
                            }}
                          />
                          <Typography
                            sx={{
                              py: 3,
                              color: plan.selected && "white",
                            }}
                          >
                            Advantages
                          </Typography>
                          {plan.advantages.map((advantage, idx) => (
                            <Box
                              key={idx}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "12px",
                                gap: 1,
                              }}
                            >
                              <CheckCircleOutlineIcon
                                sx={{
                                  ...checkIconStyle,
                                  color: plan.selected
                                    ? "white"
                                    : checkIconStyle.color,
                                  backgroundColor: plan.selected
                                    ? "rgba(255, 255, 255, 0.5)"
                                    : "rgba(76, 175, 80, 0.1)",
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "light" }}
                              >
                                {advantage.title}
                              </Typography>
                            </Box>
                          ))}
                        </>
                      )}
                      <Link to={`/plan`} style={{ textDecoration: "none" }}>
                        <Button
                          variant="outlined"
                          sx={{
                            position: "absolute",
                            bottom: 40,
                            height: "48px",
                            padding: "0 24px",
                            fontWeight: 500,
                            fontSize: "18px",
                            marginTop: "3rem",
                            color: "black",
                            borderRadius: "7px",
                            backgroundColor: plan.selected
                              ? "#fcfcfd"
                              : "transparent",
                            border: plan.selected
                              ? "1px solid #fcfcfd"
                              : "1px solid #848a98",
                            "&:hover": {
                              backgroundColor: plan.selected
                                ? "#fcfcfd"
                                : "transparent",
                              borderColor: "#848a98",
                              boxShadow: "none",
                            },
                          }}
                        >
                          {plan.buttonText}
                        </Button>
                      </Link>
                    </CardContent>
                  </StyledCard>
                </StyledLink>
              </Grid>
            ))}
          </Grid>
          {isPricing && (
            <Box id="pricing" marginBottom={"80px"}>
              <Typography
                variant="h5"
                fontWeight={700}
                fontSize={"22px"}
                align="left"
                gutterBottom
                pt={"57px"}
                pb={isPricing ? 0 : 5}
              >
                Summary of Plan Differences
              </Typography>
              {jsonData.differences.map(({ title, description }, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "4px",
                    padding: "10px 0",
                    width: "fit-content",
                  }}
                >
                  <CheckCircleOutlineIcon
                    sx={{
                      color: checkIconStyle.color,
                      marginRight: "8px",
                      fontSize: 20,
                    }}
                  />
                  <Typography
                    component="span"
                    sx={{
                      color: "#202020",
                      fontWeight: "bold",
                      marginRight: "4px",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography component="span" sx={{ color: "#202020" }}>
                    {description}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default PricingPlans;
