import React from "react";
import { Box, Paper, Typography } from "@mui/material";

import Location from "../../pages/HomeDetails/subComponents/Location";
import { theme } from "../../theme/theme";
import { SuitCase } from "../../assets/svg/SuitCase";
import { Coin } from "../../assets/svg/Coin";
import { CalendarBlank } from "../../assets/svg/CalendarBlank";
import { UniteSquare } from "../../assets/svg/UniteSquare";
import { Wall } from "../../assets/svg/Wall";
import { Resize } from "../../assets/svg/Resize";
import { MapPin } from "../../assets/svg/MapPin";
import { PawPrint } from "../../assets/svg/PawPrint";
import { CigaretteSlash } from "../../assets/svg/CigaretteSlash";
import { CreditCard } from "../../assets/svg/CreditCard";

const PropertySummary = ({ noPadding, uploadedImages }) => {
  const paperHeadings = {
    fontSize: "16px",
    color: "black",
    fontWeight: 500,
  };
  const outerBox = {
    display: "flex",
    flexDirection: "column",
    p: 2,
    mb: 3,
    boxShadow: "none",
    backgroundColor: theme.palette.background.skyBlue,
    border: `1.5px solid ${theme.palette.border.blue}`,
    rowGap: 1,
    borderRadius: "10px",
  };
  const innerBox = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: 1,
  };
  const mainText = {
    ml: 1,
    fontSize: "12px",
    fontWeight: 550,
    color: "black",
  };
  const subText = {
    fontSize: "12px",
    ml: 1,
  };
  const subPaper = {
    p: 1,
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    border: `1.5px solid ${theme.palette.border.blue}`,
    width: "150px",
    flexGrow: 1,
  };
  const iconStyle = {
    p: 1,
    height: "26px",
    width: "26px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const imagesArray = () => {
    const result = [];

    for (let i = 1; i < uploadedImages.length; i += 2) {
      if (i + 1 < uploadedImages.length) {
        result.push([uploadedImages[i], uploadedImages[i + 1]]);
      } else {
        result.push([uploadedImages[i]]);
      }
    }

    return result;
  };

  return (
    <Box p={!noPadding ? 2 : 0}>
      {/* Client Overview Section */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          Property Overview
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          <Paper sx={subPaper}>
            <Box
              sx={{
                ...iconStyle,
                backgroundColor: theme.palette.background.skyBlue,
              }}
            >
              <UniteSquare />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={mainText}>1,727 sqft</Typography>
              <Typography sx={subText}>Size</Typography>
            </Box>
          </Paper>
          <Paper variant="outlined" sx={subPaper}>
            <Box
              sx={{
                ...iconStyle,
                backgroundColor: "#daf1db",
              }}
            >
              <Wall />
            </Box>
            <Box>
              <Typography sx={mainText}>2018</Typography>
              <Typography sx={subText}>Built</Typography>
            </Box>
          </Paper>
          <Paper sx={subPaper}>
            <Box sx={{ backgroundColor: "#fffab8", ...iconStyle }}>
              <Resize />
            </Box>
            <Box>
              <Typography sx={mainText}>3 bed. / 2 bath.</Typography>
              <Typography sx={subText}>Rooms</Typography>
            </Box>
          </Paper>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          <Paper sx={subPaper}>
            <Box
              sx={{
                ...iconStyle,
                backgroundColor: theme.palette.background.skyBlue,
              }}
            >
              <MapPin />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={mainText}>
                Viktoriastrasse 29, 8057 Zürich, Switzerland
              </Typography>
              <Typography sx={subText}>Location</Typography>
            </Box>
          </Paper>
          <Paper variant="outlined" sx={{ ...subPaper, maxWidth: "30%" }}>
            <Box
              sx={{
                ...iconStyle,
                backgroundColor: "#daf1db",
              }}
            >
              <Coin />
            </Box>
            <Box>
              <Typography sx={mainText}>₣2,500</Typography>
              <Typography sx={subText}>Budget</Typography>
            </Box>
          </Paper>
        </Box>
        {/* Dummy map */}
        <Box>
          <Paper
            variant="outlined"
            sx={{
              boxShadow: "none",
              border: `1.5px solid ${theme.palette.border.blue}`,
              paddingBottom: 0,
            }}
          >
            <Location hideContact />
          </Paper>
        </Box>
      </Box>
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          Photo Upload and Enhancement
        </Typography>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Box
            sx={{
              width: "100%",
              height: "248px",
              backgroundPosition: "center",
              borderRadius: "4px",
              backgroundSize: "cover",
              backgroundImage: `url(${uploadedImages[0].url})`,
            }}
          />
          {
            imagesArray().map(images => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "120px",
                  width: "120px",
                  minWidth: "120px",
                  gap: "8px",
                }}
              >
                {
                  images.map(image => (
                    <Box
                      sx={{
                        width: "100%",
                        borderRadius: "4px",
                        height: "120px",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundImage:
                          `url(${image.url})`,
                      }}
                    />

                  ))
                }
              </Box>
            ))
          }
        </Box>
        <Typography
          variant="body2"
          sx={{ fontWeight: 400, mt: 1, fontSize: 16, color: "#202020" }}
          gutterBottom
        >
          The spacious property is located near the city center with a beautiful
          landscape and everything you need in the environment. Even though it's
          5 minutes further from your work, it offers a larger kitchen, making
          it a good compromise.
        </Typography>
      </Box>
      {/* Tenant Preferences */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
        Tenant Preferences
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <PawPrint />
            <Typography
              sx={{ fontSize: 12, fontWeight: 500, color: "#202020" }}
              variant="body1"
            >
              Pet policy: Yes
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <CigaretteSlash />
            <Typography
              sx={{ fontSize: 12, fontWeight: 500, color: "#202020" }}
              variant="body1"
            >
              Smoking policy: No
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <CalendarBlank fill="#202020" />
            <Typography
              sx={{ fontSize: 12, fontWeight: 500, color: "#202020" }}
              variant="body1"
            >
              Lease duration: 6 months
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Application Filtering Setup */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          Application Filtering Setup
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          <Paper sx={subPaper}>
            <Box
              sx={{
                ...iconStyle,
                backgroundColor: "#daf1db",
              }}
            >
              <CreditCard fill="#3E9B4F" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={mainText}>₣9,500 - ₣12,500</Typography>
              <Typography sx={subText}>Income</Typography>
            </Box>
          </Paper>
          <Paper variant="outlined" sx={subPaper}>
            <Box
              sx={{
                ...iconStyle,
                backgroundColor: "#fffab8",
              }}
            >
              <Coin fill="#9E6C00" />
            </Box>
            <Box>
              <Typography sx={mainText}>₣9,500 - ₣12,500</Typography>
              <Typography sx={subText}>Credit score</Typography>
            </Box>
          </Paper>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          <Paper sx={subPaper}>
            <Box
              sx={{
                ...iconStyle,
                backgroundColor: theme.palette.background.skyBlue,
              }}
            >
              <SuitCase />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={mainText}>Full, Part-time</Typography>
              <Typography sx={subText}>Employment</Typography>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default PropertySummary;
