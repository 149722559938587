import React from "react";
import { Box, Typography, Avatar, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import MyProperties from "./MyProperties/MyProperties";
import { Note } from "../../../assets/svg/Note";
import MyContact from "./MyContact/MyContact";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Notes from "./Notes/Notes";
import Subscription from "./Subscription/Subscription";
import Payments from "./Payments/Payments";

const Sidebar = styled(Box)(({ theme }) => ({
  width: 250,
  minWidth: "250px",
  padding: theme.spacing(3),
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const NavItem = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const MyAccount = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchTerm = queryParams.get("section") || "property";

  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <Sidebar>
        <Box sx={{ mt: "25px" }}>
          <Typography
            sx={{ color: "#202020", fontWeight: 500, fontSize: "16px" }}
          >
            Search Property
          </Typography>
          <Box
            sx={{
              marginTop: "32px",
              marginBottom: "32px",
              border: "1px solid #ddd",
            }}
          />
        </Box>
        <Box sx={{ textAlign: "center", mb: 3 }}>
          <Avatar
            src="https://via.placeholder.com/100"
            sx={{ width: 80, height: 80, margin: "auto", mb: 1 }}
          />
          <Box
            onClick={() => handleClick("/edit-profile")}
            sx={{
              textAlign: "center",
              mb: 2,
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Typography variant="h6">{`${data.firstname} ${data.lastname}`}</Typography>
            <Box sx={{ marginLeft: "12px", marginTop: "8px" }}>
              <Note />
            </Box>
          </Box>
          {data.country && (
            <Typography variant="body2" color="text.secondary">
              {data.country}
            </Typography>
          )}
        </Box>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          fullWidth
          color="primary"
          sx={{
            backgroundColor: "transparent",
            color: "#1C2024",
            borderColor: "#ddd",
            marginBottom: "40px",
          }}
          onClick={() => handleClick("/chat")}
        >
          New chat
        </Button>
        <NavItem
          onClick={() => handleClick("/my-account?section=property")}
          variant="body1"
          color={searchTerm.includes("property") ? "primary" : ""}
        >
          My Properties
        </NavItem>
        <NavItem
          onClick={() => handleClick("/my-account?section=contact")}
          variant="body1"
          color={searchTerm.includes("contact") ? "primary" : ""}
        >
          Contact Manager
        </NavItem>
        <NavItem
          onClick={() => handleClick("/my-account?section=notes")}
          color={searchTerm.includes("notes") ? "primary" : ""}
          variant="body1"
        >
          Notes
        </NavItem>
        <NavItem
          onClick={() => handleClick("/my-account?section=subscription")}
          color={searchTerm.includes("subscription") ? "primary" : ""}
          variant="body1"
        >
          Subscription
        </NavItem>
        <NavItem
          onClick={() => handleClick("/my-account?section=payments")}
          color={searchTerm.includes("payments") ? "primary" : ""}
          variant="body1"
        >
          Payments
        </NavItem>
      </Sidebar>
      {searchTerm === "property" ? (
        <MyProperties />
      ) : searchTerm === "notes" ? (
        <Notes userId={data.id} />
      ) : searchTerm === "subscription" ? (
        <Subscription />
      ) : searchTerm === "payments" ? (
        <Payments />
      ) : (
        <MyContact userId={data.id} />
      )}
    </Box>
  );
};

export default MyAccount;
