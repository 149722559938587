import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Chip,
} from "@mui/material";
import { View } from "../../../../assets/svg/View";
import { Edit } from "../../../../assets/svg/Edit";
import { Delete } from "../../../../assets/svg/Delete";
import AddIcon from "@mui/icons-material/Add";
import PropertyDetailsModal from "./AddProperty";
import DeleteConfirmationModal from "./DeleteProperty";
import { useNavigate } from "react-router-dom";

const PropertyCard = ({
  property,
  setDeletePropertyModals,
  setPropertyDetailsModal,
  navigate,
}) => (
  <Box>
    <Card
      sx={{
        display: "flex",
        maxWidth: 600,
        my: 2,
        position: "relative",
        border: "1px solid #c1d0ff",
        boxShadow: "unset",
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: 200, height: 200, objectFit: "cover" }}
        image="https://images.unsplash.com/photo-1585580829850-a074625a2f27?q=80&w=2011&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        alt={property.address}
      />
      <Chip
        label="Ad Active"
        color="success"
        size="small"
        sx={{ position: "absolute", top: 10, left: 10 }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          marginLeft: "5px",
        }}
      >
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            {property.price}{" "}
            <Typography variant="caption">per month</Typography>
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {property.address}
          </Typography>
          <Box
            sx={{ marginTop: "15px", display: "flex" }}
            variant="body2"
            color="text.secondary"
          >
            <Typography sx={{ paddingRight: "10px" }}>
              {property.beds} beds
            </Typography>
            <Typography sx={{ paddingRight: "10px" }}>
              {property.baths} baths{" "}
            </Typography>
            <Typography>{property.sqft} sqft</Typography>
          </Box>
        </CardContent>
        <CardActions
          sx={{
            padding: "unset",
            marginLeft: "10px",
            marginTop: "19px",
            lineHeight: "unset!important",
          }}
        >
          <Button
            onClick={() => setPropertyDetailsModal(true)}
            sx={{
              lineHeight: "unset!important",
              color: "#1C2024",
              marginLeft: "unset!important",
            }}
            startIcon={<View />}
            size="small"
          ></Button>
          <Button
            onClick={() => navigate("/chat-owner?id=1")}
            sx={{
              lineHeight: "unset!important",
              color: "#1C2024",
              marginLeft: "unset!important",
            }}
            startIcon={<Edit />}
            size="small"
          ></Button>
          <Button
            onClick={() => setDeletePropertyModals(true)}
            sx={{
              lineHeight: "unset!important",
              color: "#1C2024",
              marginLeft: "unset!important",
            }}
            startIcon={<Delete />}
            size="small"
          ></Button>
        </CardActions>
      </Box>
    </Card>
  </Box>
);

const ListingManagement = () => {
  const [propertyDetailsModal, setPropertyDetailsModal] = useState(false);
  const [deletePropertyModals, setDeletePropertyModals] = useState(false);
  const navigate = useNavigate();

  const sampleProperty = [
    {
      image: "https://via.placeholder.com/200x150",
      price: "F2,500",
      address: "Viktoriastrasse 29, 8057 Zürich, Switzerland",
      beds: 3,
      baths: 2,
      sqft: 1727,
    },
    {
      image: "https://via.placeholder.com/200x150",
      price: "F2,500",
      address: "Viktoriastrasse 29, 8057 Zürich, Switzerland",
      beds: 3,
      baths: 2,
      sqft: 1727,
    },
  ];

  return (
    <Box sx={{}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography
          sx={{ fontSize: "24px", fontWeight: "500" }}
          variant="h4"
          component="h1"
        >
          Listing Management
        </Typography>
        <Button
          sx={{ width: "150px", height: "37px", boxShadow: "none" }}
          variant="contained"
          color="primary"
          onClick={() => navigate("/chat-owner")}
          startIcon={<AddIcon />}
        >
          Add property
        </Button>
      </Box>
      <Box
        sx={{ display: "flex", width: "100%", gap: "12px", flexWrap: "wrap" }}
      >
        {sampleProperty.map((property, index) => (
          <Box>
            <PropertyCard
              key={index}
              property={property}
              setDeletePropertyModals={setDeletePropertyModals}
              setPropertyDetailsModal={setPropertyDetailsModal}
              navigate={navigate}
            />
          </Box>
        ))}
      </Box>
      <PropertyDetailsModal
        open={propertyDetailsModal}
        handleClose={() => setPropertyDetailsModal(false)}
      />
      <DeleteConfirmationModal
        open={deletePropertyModals}
        handleClose={() => setDeletePropertyModals(false)}
      />
    </Box>
  );
};

export default ListingManagement;
