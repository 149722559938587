import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { theme } from "../../theme/theme";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { jsonData } from "../../db";
import UpgradeModal from "../modals/upgradeModal";

const NewChat = ({ prevVisitedList }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const buttonStyling = {
    width: "100%",
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    border: `1px solid #848a98`,
    height: "48px",
  };
  return (
    <Box>
      <Box>
        <Button
          fullWidth
          sx={buttonStyling}
          onClick={handleOpen}
          startIcon={<AddIcon />}
        >
          New Chat
        </Button>
      </Box>
      <UpgradeModal
        currentPlan={jsonData.currentPlan}
        upgradePlan={jsonData.upgradePlan}
        open={open}
        onClose={handleClose}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 5 }}>
        {Object.keys(prevVisitedList).map((key) => (
          <Box key={key} sx={{ mb: 3 }}>
            <Typography sx={{ fontWeight: 500, color: "black", mb: 1 }}>
              {key}:
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {prevVisitedList[key].map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    backgroundColor: item.selected && "#edf2fe",
                    color: "#4f6ece",
                    borderRadius: "7px",
                    padding: "7px 10px",
                    fontSize: "14px",
                    display: "flex",
                    gap: 1,
                    position: "relative",
                  }}
                >
                  <Box>
                    <ChatBubbleOutlineIcon
                      sx={{
                        fontSize: "16px",
                        mt: 0.3,
                        color: theme.palette.text.secondary,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      {item.location}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "10px",
                        }}
                      >
                        Rooms:{item.rooms}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "10px",
                        }}
                      >
                        {item.size}
                      </Typography>
                    </Box>
                  </Box>
                  {item.selected && (
                    <Box>
                      <DeleteOutlineIcon
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          fontSize: "15px",
                          color: theme.palette.text.secondary,
                        }}
                      />
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default NewChat;
