import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import { theme } from "../../theme/theme";
import HeroImages from "./subComponents/HeroImages";
import AiSuggestion from "./subComponents/AiSuggestion";
import Details from "./subComponents/Details";
import Location from "./subComponents/Location";
import Feedback from "./subComponents/Feedback";
import { Link, useNavigate } from "react-router-dom";
import LoggedInLayout from "../LoggedInLayout/LoggedInLayout";

const HomeDetails = () => {
  const [view, setView] = React.useState("photos");

  const navigate = useNavigate();

  const linkStyle = {
    color: theme.palette.primary.footer,
    fontSize: "16px",
    cursor: "pointer",
    fontWeight: 600,
    textDecoration: "none",
    marginRight: "15px",
  };

  const details = {
    images: [
      "https://plus.unsplash.com/premium_photo-1723901831135-782c98d8d8e0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://plus.unsplash.com/premium_photo-1675615667752-2ccda7042e7e?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cm9vbXN8ZW58MHx8MHx8fDA%3D",
      "https://images.unsplash.com/photo-1513694203232-719a280e022f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cm9vbXN8ZW58MHx8MHx8fDA%3D",
      "https://plus.unsplash.com/premium_photo-1663126298656-33616be83c32?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://images.unsplash.com/photo-1501183638710-841dd1904471?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    ],
    address: "Johar Town 345 Lahore,Pakistan",
    beds: 3,
    baths: 3,
    sqrFeet: 1720,
    status: "Unreviewed",
    price: 5000,
    built: 2018,
    past: "Aug 15, 2024",
    upcoming: "Aug 19, 2024",
  };

  const buttonStyle = {
    backgroundColor: "transparent",
    color: "black",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    p: "3px 10px",
    border: `1px solid ${theme.palette.text.secondary}`,
    gap: 0.5,
    cursor: "pointer",
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  return (
    <LoggedInLayout>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        {/* Header Section */}

        <Box>
          <Box sx={{ px: 5 }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "60px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box onClick={() => navigate("/chat")}>
                <Typography
                  sx={{
                    backgroundColor: "transparent",
                    color: theme.palette.text.secondary,
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIosIcon
                    sx={{
                      fontSize: "12px",
                    }}
                  />
                  Back
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <ToggleButtonGroup
                  value={view}
                  exclusive
                  onChange={handleViewChange}
                  aria-label="View selection"
                  sx={{}}
                >
                  <ToggleButton
                    value="photos"
                    sx={{
                      borderRadius: "3px 0 0 3px",
                      textTransform: "none",
                      padding: "2px 14px",
                      fontSize: "10px",
                      color: view === "photos" ? "black" : "text.secondary",
                    }}
                  >
                    <PhotoSizeSelectActualOutlinedIcon
                      sx={{ fontSize: "16px", marginRight: "4px" }}
                    />
                    Photos
                  </ToggleButton>
                  <ToggleButton
                    value="map"
                    sx={{
                      borderRadius: "0 3px 3px 0",
                      textTransform: "none",
                      padding: "2px 14px",
                      fontSize: "10px",
                      color: view === "map" ? "black" : "text.secondary",
                    }}
                  >
                    <LocationOnOutlinedIcon
                      sx={{ fontSize: "16px", marginRight: "4px" }}
                    />
                    Map
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
          </Box>
        </Box>

        {/*   Below Header */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box>
            <HeroImages images={details.images} />
          </Box>
          <Box
            sx={{
              px: 5,
            }}
          >
            <AiSuggestion />
          </Box>
          <Box
            sx={{
              px: 5,
            }}
          >
            <Details details={details} />
          </Box>
          <Box
            sx={{
              px: 5,
            }}
          >
            <Location
              details={details}
              padding={5}
              width={"60%"}
              title="Location"
            />
          </Box>
          <Box
            sx={{
              px: 5,
            }}
          >
            <Feedback />
          </Box>

          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderTop: "1px solid #ddd",
                height: "100px",
                px: 5,
              }}
            >
              <Box>
                <Link
                  to="home"
                  smooth={true}
                  offset={50}
                  duration={500}
                  style={{
                    textDecoration: "none",
                    fontSize: "20px",
                    fontWeight: 700,
                    color: theme.palette.primary.footer,
                  }}
                >
                  HOME AI
                </Link>
              </Box>

              <Box
                sx={{
                  [theme.breakpoints.down("md")]: {
                    display: "none",
                  },
                }}
              >
                <Link to="/privacy-policy" style={{ ...linkStyle }}>
                  Search Property
                </Link>
                <Link to="/privacy-policy" style={{ ...linkStyle }}>
                  Privacy Policy
                </Link>
                <Link to="/terms-of-service" style={{ ...linkStyle }}>
                  Terms and Condition
                </Link>
                <Link to="/terms-of-service" style={{ ...linkStyle }}>
                  Support
                </Link>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  color: theme.palette.primary,
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  © 2024 Home Ai, LLC
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </LoggedInLayout>
  );
};

export default HomeDetails;
