import React from "react";
import { Modal, Typography, IconButton, Divider, Box } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import Plan from "../plan/plan";
import AIAvatar from "../../assets/svg/AIAvatar";

const StyledModalBox = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 828,
  maxWidth: "calc(100% - 20px)",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
  boxShadow: 24,
}));

const ModalHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 40px",
}));

const PlansContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  alignItems: "flex-start",
  marginTop: "48px",
  paddingLeft: 44,
}));

const UpgradeModal = ({ currentPlan, upgradePlan, open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <StyledModalBox>
        <ModalHeader>
          <Typography
            sx={{ fontWeight: 700, fontSize: 18 }}
            id="modal-title"
            variant="h6"
            component="h2"
          >
            Upgrade Plan
          </Typography>
          <IconButton sx={{ pr: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <Divider sx={{ width: "100%" }} />
        <Box sx={{ padding: "32px 40px" }}>
          <div style={{ display: "flex", gap: "8px" }}>
            <AIAvatar />
            <Typography
              variant="body3"
              sx={{ fontWeight: 500, color: "#202020", mb: 2 }}
            >
              AI Agent
            </Typography>
          </div>
          <Typography variant="body3" sx={{ mb: 4, ml: "44px" }}>
            You have reached your limit of chats. Upgrade your plan and enjoy
            more chats.
          </Typography>

          <PlansContainer>
            <Plan
              name={currentPlan.name}
              price={currentPlan.price}
              keyFeatures={currentPlan.keyFeatures}
            />
            <Plan
              name={upgradePlan.name}
              noScroll
              price={upgradePlan.price}
              keyFeatures={upgradePlan.keyFeatures}
              isUpgrade={true}
            />
          </PlansContainer>
        </Box>
      </StyledModalBox>
    </Modal>
  );
};

export default UpgradeModal;
