import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { counter } from "../../../store/slices/counterSlice";
import { useDispatch } from "react-redux";
import { errorHandler } from "../../../utils";
import { jsonData } from "../../../db";
const Join = () => {
  const [count, setCounter] = useState(null);

  const dispatch = useDispatch();
  const getCount = async () => {
    try {
      const data = await dispatch(counter());
      if (
        data.payload.status === 200 &&
        data.meta.requestStatus === "fulfilled"
      ) {
        setCounter(data?.payload?.data);
      }
    } catch (error) {
      console.log("Error", error);
      errorHandler(error);
    }
  };

  useEffect(() => {
    //  getCount();
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        pb: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "40px",
        background: "#F7F9FF",
      }}
    >
      <Typography
        variant="h5"
        component="h3"
        sx={{ fontWeight: 700, fontSize: "35px" }}
      >
        Join Us
      </Typography>

      <Box>
        <Typography
          variant="h1"
          component="h2"
          sx={{
            backgroundColor: "#e1e9ff",
            textAlign: "center",
            py: "30px",
            width: { xs: "220px", sm: "440px" },
            borderRadius: 1,
            fontSize: "60px",
          }}
        >
          <CountUp
            start={0}
            duration={10.75}
            separator=","
            decimal=","
            end={jsonData?.counter?.count || 0}
          />
        </Typography>
        <Typography
          sx={{ textAlign: "center", mt: 1, fontSize: "18px", fontWeight: 400 }}
        >
          Available Ads
        </Typography>
      </Box>

      <Button
        variant="contained"
        sx={{
          fontSize: "20px",
          height: "48px",
          width: "137px",
          background: "#3E63DD",
          borderRadius: "8px",
        }}
      >
        Get Started
      </Button>
    </Box>
  );
};

export default Join;
