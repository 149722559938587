import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { stepper } from "../../store/slices/stepper";
import { useDispatch } from "react-redux";
import { errorHandler } from "../../utils";
import Loading from "../Loading/Loading";
import { jsonData } from "../../db";
import { styled } from "@mui/material";

const StyledStepper = styled(Stepper)(({ theme }) => ({
  "& .MuiStepConnector-root": {
    marginLeft: "16px!important",
  },
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  svg: {
    width: 32,
    height: 32,
  },
  circle: {
    fill: "#D9D9E0",
  },
  text: {
    fill: "#202020",
  },
  "&:not(.Mui-disabled)": {
    circle: {
      fill: "#3E63DD",
    },
    text: {
      fill: "#fff",
    },
  },
}));

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState([]);

  const dispatch = useDispatch();

  const getStepper = async () => {
    setIsLoading(true);
    try {
      const data = await dispatch(stepper());
      if (data?.payload?.status === 200 && data?.meta?.requestStatus) {
        setSteps(data?.payload?.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error", error);
      errorHandler(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // getStepper();
  }, []);

  return (
    <>
      {!isLoading ? (
        <Box sx={{ maxWidth: 400 }}>
          <StyledStepper activeStep={activeStep} orientation="vertical">
            {jsonData.stepper?.map((step, index) => (
              <Step key={index}>
                <StyledStepLabel>{step.label}</StyledStepLabel>
              </Step>
            ))}
          </StyledStepper>
        </Box>
      ) : (
        <Loading />
      )}
    </>
  );
}
