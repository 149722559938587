import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Files } from "../../../../assets/svg/Files";
import DocumentsTable from "../../aiCard/DocumentsTable";
import AddLeaseModal from "../../../modals/addLeaseModal";
import { useState } from "react";
import NewContractModal from "../../../modals/newContractModal";
import SignContractModal from "../../../modals/signContractModal";
import NewContractSuccess from "../../../modals/newContractSuccess";

const Document = () => {
  const [addLeaseModal, setAddLeaseModal] = useState(false);
  const [newContractModal, setNewContractModal] = useState(false);
  const [signContractModal, setSignContractModal] = useState(false);
  const [newContractSuccessModal, setNewContractSuccessModal] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        height: "calc(100% - 48px)",
        px: 4,
        py: 3,
        backgroundColor: "#F7F9FF",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "500" }} variant="h4">
          Document Management System
        </Typography>
        <Button
          sx={{ width: "150px", height: "37px", boxShadow: "none" }}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setAddLeaseModal(true)}
        >
          Add Lease
        </Button>
      </Box>
      <Box sx={{ height: "100%" }}>
        <DocumentsTable />
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
            gap: "4px",
          }}
        >
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "48px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#EDF2FE",
            }}
          >
            <Files />
          </Box>
          <Typography
            sx={{ fontSize: 14, color: "#202020", fontWeight: 500, mb: 0 }}
            variant="body2"
          >
            No documents
          </Typography>
          <Typography
            sx={{ fontSize: 12, color: "#646464", fontWeight: 400, mb: 0 }}
            variant="body2"
          >
            All rental contracts will be displayed here
          </Typography>
        </Box> */}
      </Box>
      <AddLeaseModal
        open={addLeaseModal}
        handleClose={() => setAddLeaseModal(false)}
        openNewContractModal={() => {
          setAddLeaseModal(false);
          setNewContractModal(true);
        }}
      />
      <NewContractModal
        open={newContractModal}
        handleClose={() => setNewContractModal(false)}
        handleConfirm={() => {
          setNewContractModal(false);
          setSignContractModal(true);
        }}
      />
      <SignContractModal
        open={signContractModal}
        handleClose={() => setSignContractModal(false)}
        handleConfirm={() => {
          setSignContractModal(false);
          setNewContractSuccessModal(true);
        }}
      />
      <NewContractSuccess
        open={newContractSuccessModal}
        handleClose={() => setNewContractSuccessModal(false)}
      />
    </Box>
  );
};

export default Document;
