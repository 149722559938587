import React from "react";
import Layout from "../Layout/Layout";
import FeatureBox from "../../components/sections/featureSection/Feature";

const Features = () => {
  const heading = "Features";
  const text =
    "Explore the innovative features of Home AI that make appartment hunting effortless and efficient";
  return (
    <Layout heading={heading} text={text}>
      <FeatureBox title={"Explore the Features For Tenants"} isFeature />
      <FeatureBox title={"Explore the Features For Owners"} isFeature />
    </Layout>
  );
};

export default Features;
