import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:first-of-type td, &:first-of-type th": {
    borderTop: "none",
  },

  "&:last-of-type": {
    borderBottom: "1px solid #ddd", // Border for last row
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#fbfcff", // Red background for even rows
  },
  "& td, & th": {
    border: "none", // Remove borders for table body
  },
}));

const BillingHistoryTable = () => {
  const billingData = [
    { date: "01/08/2024", plan: "Premium Advance Search", amount: "£49.99" },
    { date: "01/07/2024", plan: "Premium Advance Search", amount: "£49.99" },
    { date: "01/06/2024", plan: "Premium Advance Search", amount: "£49.99" },
    { date: "01/05/2024", plan: "Premium Advance Search", amount: "£49.99" },
    { date: "01/04/2024", plan: "Premium Advance Search", amount: "£49.99" },
    { date: "01/03/2024", plan: "Premium Advance Search", amount: "£49.99" },
    { date: "01/02/2024", plan: "Premium Advance Search", amount: "£49.99" },
  ];

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table
        sx={{
          minWidth: 650,
          marginTop: "32px",
        }}
        aria-label="billing history table"
      >
        <TableHead
          sx={{
            backgroundColor: "#f7f9ff!important",
            borderBottom: "1px solid #ddd!important",
          }}
        >
          <StyledTableRow>
            <StyledTableCell sx={{ color: "#8E8E93" }}>Date</StyledTableCell>
            <StyledTableCell sx={{ color: "#8E8E93" }}>Plan</StyledTableCell>
            <StyledTableCell sx={{ color: "#8E8E93" }}>Amount</StyledTableCell>
            <StyledTableCell sx={{ color: "#8E8E93" }}>
              Download Invoice
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {billingData.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{row.date}</StyledTableCell>
              <StyledTableCell>{row.plan}</StyledTableCell>
              <StyledTableCell>{row.amount}</StyledTableCell>
              <StyledTableCell>
                <Link href="#" color="primary" underline="always">
                  Download
                </Link>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BillingHistoryTable;
