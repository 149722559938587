import React from "react";
import Layout from "../Layout/Layout";
import HowItWorks from "../../components/sections/workSection/WorkSection";
const Owners = () => {
  const heading = "How it works for Hosts";
  const text =
    "With our SmartView Experience, you can showcase your property effortlessly while maximizing convenience and security. Here’s how it works:";
  return (
    <Layout heading={heading} text={text} isOwners>
      <HowItWorks title={"Explore the Process"} isOwners />
    </Layout>
  );
};

export default Owners;
