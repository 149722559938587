import React from "react";
import { Grid, Typography, Box, Paper, Container, Link } from "@mui/material";
import { styled } from "@mui/system";
import first from "../../../assets/1.svg";
import second from "../../../assets/2.svg";
import third from "../../../assets/3.svg";
import fourth from "../../../assets/4.svg";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F5F8FF",
  padding: theme.spacing(3),
  borderRadius: "8px",
  textAlign: "left",
  boxShadow: "none",
  minHeight: "180px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  border: "1px solid #E0E5FF",
  height: "100%",
}));

const FeatureBox = ({ title, isFeature }) => {
  const featureItems = [
    {
      title: "Real-Time Market Scan",
      description:
        "This feature represents the highest level of service, ensuring that users are always the first to know about new listings. It showcases the proactive nature of our AI, which is crucial for high-demand renters in competitive markets.",
      iconColor: "#DCE4FF", // Color for icon placeholder
      logo: first,
      width: 60,
    },
    {
      title: "In-Depth Analysis of Text and Photos",
      description:
        "This feature emphasizes the AI's ability to go beyond basic data by analyzing qualitative factors like view quality, noise levels, and interior design.",
      iconColor: "#E0E5FF", // Color for icon placeholder
      logo: second,
      width: 40,
    },
    {
      title: "Instant Application Submission",
      description:
        "This feature is crucial because it demonstrates the AI’s ability to act immediately on behalf of the user, ensuring they are among the first applicants for a desirable property. It highlights the AI's proactive nature and the value of being first in line.",
      iconColor: "#DCE4FF", // Color for icon placeholder
      logo: third,
      width: 40,
    },
    {
      title: "Advanced Data-Driven Search",
      description:
        "This feature highlights the AI’s ability to leverage over 60 data sources for an intelligent, dynamic search. The AI considers a wide range of factors beyond basic filters, such as commute times to  work, family friend and points of interest, negative news, taxes, potential strict deadlines and more. Additionally, the search evolves over time, adjusting criteria as deadlines approach to ensure a timely and accurate match.",
      iconColor: "#E0E5FF", // Color for icon placeholder
      logo: fourth,
      width: 60,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Box id="features" sx={{ flexGrow: 1, padding: "48px 0 80px", mt: 6 }}>
        <Typography
          variant="h5"
          fontWeight={700}
          fontSize={"35px"}
          align="center"
          gutterBottom
        >
          {title}
        </Typography>
        {!isFeature && (
          <Typography
            variant="subtitle1"
            sx={{ fontSize: "14px", color: "#6B7280" }}
            align="center"
            gutterBottom
          >
            Explore the Features
          </Typography>
        )}

        <Grid
          container
          gap={2}
          sx={{
            marginTop: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{
              display: {
                xs: "block", // Display items as blocks on extra-small screens (mobile)
                sm: "block", // Display items as blocks on small screens (tablets)
                md: "grid", // Use grid layout on medium and larger screens
              },
              gridAutoRows: "1fr",
            }}
          >
            {featureItems.map((item, index) => {
              const isFirstRow = index < 2;
              return (
                <Grid
                  item
                  key={index}
                  sx={{
                    gridColumn: isFirstRow
                      ? index === 0
                        ? "span 3"
                        : "span 1"
                      : index === 2
                      ? "span 1"
                      : "span 3",
                    gridRow: isFirstRow ? "1 / 2" : "2 / 3",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: {
                      xs: "auto", // Flexible height on extra-small screens
                      sm: "auto", // Flexible height on small screens
                      md: "100%", // Full height on medium and larger screens
                    },
                  }}
                >
                  <StyledPaper
                    elevation={0}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link href="" underline="none">
                      <Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 700,
                            color: "black",
                            fontSize: "24px",
                            mb: 2,
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ color: "#6B7280", fontSize: "16px", mb: 3 }}
                        >
                          {item.description}
                        </Typography>
                      </Box>
                      <Box
                        component="img"
                        src={item.logo}
                        alt={item.title}
                        sx={{
                          width: "100%",
                          height: "auto",
                          maxHeight: { xs: "200px", md: "250px" },
                          objectFit: "contain",
                          mt: "auto",
                        }}
                      />
                    </Link>
                  </StyledPaper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default FeatureBox;
