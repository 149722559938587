import React from "react";
import Layout from "../Layout/Layout";
import PlanSection from "../../components/sections/planSection/Plan";
import { theme } from "../../theme/theme";

const Plan = () => {
  return (
    <Layout backgroundColor={theme.palette.background.lightBlue}>
      <PlanSection isPricing />
    </Layout>
  );
};

export default Plan;
