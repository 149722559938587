import React, { useState, useEffect } from "react";
import { Modal, Box, Button, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import googleIcon from "../../assets/login/google-logo.svg";
import ImageBox from "./ImageBox";
import CloseIcon from "@mui/icons-material/Close";
import ImageComponent from "../Image/Image";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import authService from "../../services/authService";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 580px)": {
    alignItems: "flex-start",
    paddingTop: 10,
    paddingBottom: 10,
    overflow: "auto",
  },
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  width: "calc(100% - 20px)",
  overflow: "hidden",
  maxWidth: 763,
  maxHeight: "calc(100vh - 50px)",
  overflowY: "scroll",

  "@media (max-width: 580px)": {
    height: "auto",
  },
}));

const SocialButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "40px",
  display: "flex",
  justifyContent: "flex-center",
  borderRadius: "6px",
  border: "0!important",
  background: "rgba(0, 0, 51, 0.06)",
  marginBottom: theme.spacing(1),
  color: "#646464",
  fontSize: "16px",
  fontWeight: 500,
  "& .MuiButton-startIcon": {
    marginRight: theme.spacing(2),
  },
}));

const LoginSignupModal = ({ open, onClose }) => {
  const [userType, setUserType] = useState("tenant");
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      const googleAuthUrl = await authService.initiateGoogleLogin(userType);
      window.location.href = googleAuthUrl.authorization_url;
    } catch (error) {
      console.error("Error initiating Google Sign-In:", error);
      toast.error("Failed to initiate Google Sign-In");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const access_token = urlParams.get("access_token");
    const token_type = urlParams.get("token_type");

    if (access_token) {
      localStorage.setItem(
        "user",
        JSON.stringify({ access_token, token_type })
      );
      navigate("/my-account?section=property");
    }
  }, []);

  const handleChange = (option) => {
    setUserType(option);
  };

  const handleMainLogin = () => {
    setShowLoginForm(true);
    setShowSignupForm(false);
  };

  const handleSignUp = () => {
    setShowSignupForm(true);
    setShowLoginForm(false);
  };

  const handleBackToMain = () => {
    setShowLoginForm(false);
    setShowSignupForm(false);
  };

  const handleLoginSubmit = async (data) => {
    try {
      const login = await authService.login(data.email, data.password);
      if (login.error) {
        toast.error(login.error || "Login failed");
      }
      if (login.access_token) {
        onClose();
        navigate("/my-account?section=property");
      }
    } catch (error) {
      toast.error(error.detail || "Login failed");
    }
  };

  const handleSignupSubmit = async (userData) => {
    try {
      const register = await authService.register(userData);
      if (register.error) {
        toast.error(register.detail || "Registration failed");
      }
      if (register.id) {
        onClose();
        handleLoginSubmit(userData);
      }
    } catch (error) {
      toast.error(error.detail || "Registration failed");
    }
  };

  return (
    <>
      <StyledModal open={open} onClose={onClose}>
        <ModalContent>
          <Box
            p={0}
            sx={{
              display: "flex",
              height: "100%",
              "@media (max-width: 580px)": { display: "block" },
            }}
          >
            <ImageBox />
            <Box
              sx={{
                width: "50%",
                padding: "32px",
                "@media (max-width: 580px)": { width: "auto" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 4,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 700,
                    color: "black",
                    marginBottom: 0,
                  }}
                  variant="h5"
                  component="h2"
                  align="center"
                  gutterBottom
                >
                  Sign Up or Login
                </Typography>

                {showLoginForm || showSignupForm ? (
                  <IconButton onClick={handleBackToMain} sx={{ mr: 1 }}>
                    <ArrowBackIcon />
                  </IconButton>
                ) : (
                  <CloseIcon onClick={onClose} />
                )}
              </Box>
              {!showLoginForm && !showSignupForm && (
                <>
                  <Box
                    sx={{
                      backgroundColor: "#f0f0f0",
                      borderRadius: "8px",
                      padding: "2px",
                      overflow: "hidden",
                    }}
                  >
                    <Button
                      onClick={() => handleChange("tenant")}
                      sx={{
                        backgroundColor:
                          userType === "tenant" ? "white" : "transparent",
                        color: userType === "tenant" ? "black" : "grey",
                        "&:hover": {
                          backgroundColor:
                            userType === "tenant"
                              ? "white"
                              : "rgba(0, 0, 0, 0.05)",
                        },
                        transition: "background-color 0.3s, color 0.3s",
                        borderRadius: "6px",
                        padding: "8px 16px",
                        minWidth: "120px",
                        width: "50%",
                      }}
                    >
                      <Typography variant="body2">I'm a Tenant</Typography>
                    </Button>
                    <Button
                      onClick={() => handleChange("owner")}
                      sx={{
                        backgroundColor:
                          userType === "owner" ? "white" : "transparent",
                        color: userType === "owner" ? "black" : "grey",
                        "&:hover": {
                          backgroundColor:
                            userType === "owner"
                              ? "white"
                              : "rgba(0, 0, 0, 0.05)",
                        },
                        transition: "background-color 0.3s, color 0.3s",
                        borderRadius: "6px",
                        padding: "8px 16px",
                        minWidth: "120px",
                        width: "50%",
                      }}
                    >
                      <Typography variant="body2">I'm an Owner</Typography>
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      marginTop: 4,
                    }}
                  >
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleMainLogin}
                      sx={{ marginBottom: 2 }}
                    >
                      Login
                    </Button>
                    <Typography variant="body2" align="center">
                      or{" "}
                      <Button
                        color="primary"
                        onClick={handleSignUp}
                        sx={{ textTransform: "none", fontSize: "14px" }}
                      >
                        Sign Up
                      </Button>
                    </Typography>
                    <SocialButton
                      variant="outlined"
                      startIcon={<ImageComponent src={googleIcon} />}
                      onClick={handleGoogleSignIn}
                      fullWidth
                    >
                      Continue with Google
                    </SocialButton>
                    {/* <SocialButton
                    variant="outlined"
                    startIcon={<ImageComponent src={microsoftIcon} />}
                    fullWidth
                  >
                    Continue with Microsoft
                  </SocialButton>
                  <SocialButton
                    variant="outlined"
                    startIcon={<ImageComponent src={appleIcon} />}
                    fullWidth
                  >
                    Continue with Apple
                  </SocialButton> */}
                  </Box>
                </>
              )}
              {showLoginForm && (
                <LoginForm
                  onBack={handleBackToMain}
                  onSubmit={handleLoginSubmit}
                />
              )}
              {showSignupForm && (
                <SignupForm
                  onBack={handleBackToMain}
                  onSubmit={handleSignupSubmit}
                />
              )}
            </Box>
          </Box>
        </ModalContent>
      </StyledModal>
      <ToastContainer position="top-right" autoClose={5000} />
    </>
  );
};

export default LoginSignupModal;
