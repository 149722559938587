import { Box, Grid, Typography, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Stepper from "../../components/stepper/Stepper";
import Insights from "../../components/insights/Insights";
import MessageField from "../../components/messageField/MessageField";
import MessageScreen from "../../components/messageScreen/MessageScreen";
import NewChat from "../../components/newChat/NewChat";
import Gallery from "../../components/gallery/Gallery";
import { getChat, insight } from "../../store/slices/chatSlice";
import { errorHandler } from "../../utils";
import { useDispatch } from "react-redux";
import ClientOverview from "../../components/clientSummary/ClientSummary";
import AIAvatar from "../../assets/svg/AIAvatar";
import { theme } from "../../theme/theme";
import LoggedInLayout from "../LoggedInLayout/LoggedInLayout";
const Chat = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const scrollRef = useRef(null);

  const [conversation, setConversation] = useState([
    { sender: "AI", text: "Hello! How can I assist you today?" },
    { sender: "User", text: "I need help with my account." },
    { sender: "AI", text: "Hello! How can I assist you today?" },
    { sender: "User", text: "I need help with my account." },
    { sender: "AI", text: "Hello! How can I assist you today?" },
    { sender: "User", text: "I need help with my account." },
    { sender: "AI", text: "Hello! How can I assist you today?" },
    { sender: "User", text: "I need help with my account." },
  ]);

  const [gallery, setGallery] = useState([
    {
      images: [
        "https://plus.unsplash.com/premium_photo-1723901831135-782c98d8d8e0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      address: "Johar Town 345 Lahore,Pakistan",
      beds: 3,
      baths: 3,
      sqrFeet: 1720,
    },
    {
      images: [
        "https://images.unsplash.com/photo-1523755231516-e43fd2e8dca5?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      address: "Johar Town 345 Lahore,Pakistan",
      beds: 3,
      baths: 3,
      sqrFeet: 1720,
    },
    {
      images: [
        "https://plus.unsplash.com/premium_photo-1663126298656-33616be83c32?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      address: "Johar Town 345 Lahore,Pakistan",
      beds: 3,
      baths: 3,
      sqrFeet: 1720,
    },
    {
      images: [
        "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      address: "Johar Town 345 Lahore,Pakistan",
      beds: 3,
      baths: 3,
      sqrFeet: 1720,
    },
  ]);

  const [prevVisitedList, setPrevVisitedList] = useState({
    Today: [
      { location: "Zurich", rooms: 2, size: "2000-2500CHF", selected: true },
      { location: "Zurich", rooms: 2, size: "2000-2500CHF" },
    ],
    "Previous 7 Days": [
      { location: "Bern", rooms: 2, size: "2000-2500CHF" },
      { location: "Geneva", rooms: 2, size: "2000-2500CHF" },
      { location: "Chur", rooms: 2, size: "2000-2500CHF" },
    ],
  });

  const [isChatCompleted, setIsChatCompleted] = useState(false);
  const [message, setMessage] = useState({ text: "", sender: "User" });

  const dispatch = useDispatch();
  const boxRef = useRef(null);

  useEffect(() => {
    handleScrollBottom();
    // getInsights();
  }, [conversation, done]);

  const handleScrollBottom = () => {
    if (scrollRef.current?.scrollIntoView) {
      setTimeout(() => {
        scrollRef.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }, 0);
    }
  };

  useEffect(() => {
    //  getQuestion();
  });

  const getQuestion = async (id) => {
    try {
      const data = await dispatch(getChat({ id }));
      if (
        data.payload.status === 200 &&
        data.meta.requestStatus === "fulfilled"
      ) {
        setConversation((prev) => [...prev, ...data.payload.data]);
      }
    } catch (error) {
      errorHandler(error);
    }
  };
  const getInsights = async () => {
    try {
      const data = await dispatch(insight());
      if (
        data.payload.status === 200 &&
        data.meta.requestStatus === "fulfilled"
      ) {
        // setInsights(data.payload.data);
      }
    } catch (error) {
      errorHandler(error);
    }
  };
  const handleDone = () => {
    setDone(true);
  };
  const sendHandler = () => {
    setConversation((prev) => [...prev, { ...message }]);
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setMessage({ ...message, [name]: value });
  };
  return (
    // <Box sx={{ height: "calc(100vh - 50px)" }}>
    //   <Box>
    //     <CommonHeader />
    //   </Box>
    <LoggedInLayout hideSubscription>
      <Box
        sx={{
          height: "calc(100vh - 64px)",
        }}
      >
        <Grid
          sx={{
            px: 5,
            marginTop: 0,
            height: "100%",
          }}
          container
          spacing={2}
        >
          {/* Stepper Section */}
          <Grid
            sx={{
              borderRight: "1px solid #00002F26",
              height: "100%",
              overflowY: "scroll",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            item
            xs={2.5}
          >
            {!isChatCompleted ? (
              <Box sx={{ pr: 0.5, pt: 2.5 }}>
                <Typography sx={{ pb: 2, color: "black", fontWeight: 600 }}>
                  Search Property
                </Typography>
                <Stepper />
              </Box>
            ) : (
              <Box sx={{ pr: 5, pt: "20px" }}>
                <NewChat prevVisitedList={prevVisitedList} />
              </Box>
            )}
          </Grid>

          {/* MessageScreen Section */}
          {!isChatCompleted ? (
            <Grid
              item
              xs={7}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                pt: "0!important",
                pr: "16px",
                pb: "16px",
                "& .MuiGrid-item": {
                  paddingLeft: "30px !important",
                  paddingTop: 0,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <Box sx={{ padding: 4 }}>
                  <Typography
                    sx={{ color: "black", fontWeight: 600, fontSize: "20px" }}
                  >
                    Hello! I'm here to help you with the best choose.
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Hello! I'm here to help you with the best choose.
                  </Typography>
                </Box>
                <Box
                  ref={boxRef}
                  sx={{
                    height: "100%",
                  }}
                >
                  <MessageScreen chat={conversation} />
                  <Box>
                    <ClientOverview />
                  </Box>
                  <Box p={2}>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Box sx={{ mr: 1 }}>
                        <AIAvatar />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            color: "black",
                            fontWeight: 500,
                          }}
                        >
                          AI Agent
                        </Typography>
                        <Typography
                          sx={{ fontSize: "14px", color: "black", mt: 1 }}
                        >
                          Check All your search parameters.Do you want to edit
                          anything or everything is good?{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                        border: `1px solid ${theme.palette.border.grey}`,
                        borderRadius: "10px",
                        my: 5,
                        py: 3,
                        flexWrap: "wrap",
                        [theme.breakpoints.down("md")]: {
                          px: 2,
                        },
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          fontWeight: 400,
                        }}
                      >
                        I want to edit my search parameters
                      </Button>
                      <Button
                        onClick={handleDone}
                        variant="contained"
                        sx={{
                          fontWeight: 400,
                        }}
                      >
                        I'm done
                      </Button>
                    </Box>
                  </Box>

                  {done && (
                    <Box p={2}>
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Box sx={{ mr: 1 }}>
                          <AIAvatar />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              color: "black",
                              fontWeight: 500,
                            }}
                          >
                            AI Agent
                          </Typography>
                          <Typography
                            sx={{ fontSize: "14px", color: "black", mt: 1 }}
                          >
                            Great! Than there is the plan:
                          </Typography>
                          <Typography
                            sx={{ fontSize: "14px", color: "black", mt: 3 }}
                          >
                            1. We are going to take all these criteria and then
                            we are going to look at around 60+ data sources.
                          </Typography>
                          <Typography
                            sx={{ fontSize: "14px", color: "black", mt: 1 }}
                          >
                            2. We are going to match them and criteria will
                            evolve based on time. So as we go closer to your
                            fixed deadline September 24, 2024 we'll be update
                            our criteria, for example we will look in additional
                            areas, or we'll be in other strict when it comes to
                            the budget and so on. Just to give you a better
                            chance as a meeting your strict criteria, exactly as
                            a real estate agent will actually do.
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 2,
                          border: `1px solid ${theme.palette.border.grey}`,
                          borderRadius: "10px",
                          my: 5,
                          py: 3,
                          flexWrap: "wrap",
                          [theme.breakpoints.down("md")]: {
                            px: 2,
                          },
                        }}
                      >
                        <Button
                          onClick={() => setIsChatCompleted(true)}
                          variant="contained"
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          Got that, lets see search results
                        </Button>
                      </Box>
                    </Box>
                  )}
                  <div ref={scrollRef} />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <MessageField
                  onChangeHandler={onChangeHandler}
                  sendHandler={sendHandler}
                />
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              xs={7}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                pr: 6.5,
                pb: "16px",
                "& .MuiGrid-item": {
                  paddingLeft: "30px !important",
                  paddingTop: 0,
                },
              }}
            >
              {<Gallery gallery={gallery} />}
            </Grid>
          )}

          {/* Insights Section */}
          <Grid
            sx={{
              borderLeft: "1px solid #00002F26",
              height: "100%",
              overflowY: "scroll",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            item
            xs={2.5}
          >
            <Box
              sx={{
                height: "100%",
              }}
            >
              <Insights adjust={isChatCompleted} title="Insights" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </LoggedInLayout>
  );
};

export default Chat;
