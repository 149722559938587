import { Box, Grid, Typography } from '@mui/material';
import React from 'react'
import { theme } from '../../../theme/theme';

const HeroImages = ({ images }) => {

   const smallImages = {
      width: "100%",
      height: "200px",
      objectFit: "cover",
      borderRadius: "5px",
   }
   return (
      <Grid
         container spacing={1} sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            px: 5,
            alignItems: "center",
            ml: 0
         }}>

         <Grid item xs={12} md={7} sx={{
            position: "relative",
            flexGrow: 1
         }}>
            <Typography sx={{
               position: "absolute",
               top: 20,
               left: 30,
               color: "white",
               backgroundColor: "#30a46c",
               px: 1,
               py: 0.5,
               borderRadius: "5px",
               fontSize: "12px"
            }}>Available</Typography>
            <img style={{
               width: "100%",
               borderRadius: "5px",
               height: "410px",
               objectFit: "cover"
            }} src={images[0]} alt="homeai" />
         </Grid>

         <Grid item xs={12} md={5} sx={{
            display: "flex",
            // flexWrap: "wrap",
            height: "100%",
            flexGrow: 1,
            columnGap: 1
         }}>
            <Box sx={{
               width: "50%",
               height: "100%",
               display: "flex",
               flexDirection: "column",
               flexGrow: 1,
               gap: 0.5

            }}>
               <Box sx={{
                  width: "100%",
                  height: "100%",
               }}>
                  <img style={smallImages} src={images[1]} alt="homeai" />
               </Box>
               <Box sx={{
                  width: "100%",
                  height: "100%"

               }}>
                  <img style={smallImages} src={images[2]} alt="homeai" />
               </Box>
            </Box>
            <Box sx={{
               width: "48%",
               height: "50%",
               display: "flex",
               flexDirection: "column",
               flexGrow: 1,
               gap: 0.5
            }}>
               <Box sx={{
                  width: "100%",
                  height: "100%",
               }}>
                  <img style={smallImages} src={images[3]} alt="homeai" />
               </Box>
               <Box sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative"

               }}>
                  <img style={smallImages} src={images[4]} alt="homeai" />
                  <Typography sx={{
                     color: "white",
                     position: "absolute",
                     bottom: 10,
                     right: 15,
                     backgroundColor: "#8b8d98",
                     px: 1.5,
                     py: 0.5,
                     borderRadius: "5px",
                     fontSize: "12px",
                     [theme.breakpoints.down("sm")]:{
                     px: 0.5,
                     fontSize: "10px",

                     }
                  }}>See all {images.length} photos</Typography>
               </Box>
            </Box>
         </Grid>

      </Grid>

   )
}

export default HeroImages;