import React from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  Paper,
  Chip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AiSuggestion from "../../../../pages/HomeDetails/subComponents/AiSuggestion";
import LocationMap from "../../../../pages/HomeDetails/subComponents/Location";

const PropertyDetailsModal = ({ open, handleClose }) => {
  const images = [
    "https://plus.unsplash.com/premium_photo-1723901831135-782c98d8d8e0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://plus.unsplash.com/premium_photo-1675615667752-2ccda7042e7e?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cm9vbXN8ZW58MHx8MHx8fDA%3D",
    "https://images.unsplash.com/photo-1513694203232-719a280e022f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cm9vbXN8ZW58MHx8MHx8fDA%3D",
    "https://plus.unsplash.com/premium_photo-1663126298656-33616be83c32?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1501183638710-841dd1904471?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ];

  const mainImage = images[0];
  const thumbnails = images.slice(1);

  const details = {
    address: "Johar Town 345 Lahore,Pakistan",
    built: 2018,
    past: "Aug 15, 2024",
    upcoming: "Aug 19, 2024",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="property-details-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 900,
          bgcolor: "background.paper",
          boxShadow: 24,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          sx={{ paddingLeft: "40px", paddingTop: "17px" }}
          variant="h6"
          component="h2"
          gutterBottom
        >
          Property details
        </Typography>

        <Box
          sx={{
            border: "1px solid #ddd",
            marginBottom: "45px",
            marginTop: "17px",
          }}
        />

        {/* Image Gallery */}

        <Box
          sx={{
            position: "relative",
            mb: 3,
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  position: "relative",
                  height: 310,
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                <img
                  src={mainImage}
                  alt="Main Property View"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <Chip
                  label="Ad Active"
                  color="success"
                  size="small"
                  sx={{
                    position: "absolute",
                    top: 16,
                    left: 16,
                    backgroundColor: "#4CAF50",
                    color: "white",
                    fontWeight: "bold",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} container spacing={1}>
              {thumbnails.map((img, index) => (
                <Grid item xs={6} key={index}>
                  <Box
                    sx={{
                      position: "relative",
                      height: "150px",
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={img}
                      alt={`Thumbnail ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    {index === 3 && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: "rgba(0,0,0,0.5)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          See all 50 photos
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>

        {/* AI Recommendations */}
        <Box sx={{ marginLeft: "40px", marginRight: "40px" }}>
          <AiSuggestion hideUpgrade fullWidth />
        </Box>

        {/* Property Details */}

        <Box sx={{ mt: 3, marginLeft: "40px", marginRight: "40px" }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", mb: 3, fontSize: "24px" }}
          >
            Viktoriastrasse 29, 8057 Zürich, Switzerland
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex", marginBottom: "18px" }}>
                <Typography sx={{ marginRight: "20px" }}>Price</Typography>
                <Typography>₣2,500 per month</Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ marginRight: "20px" }}>Rooms</Typography>
                <Typography>3 Bed / 2 Ba</Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: "flex", marginBottom: "18px" }}>
                <Typography sx={{ marginRight: "20px" }}>Size</Typography>
                <Typography>1,727 sqft</Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ marginRight: "20px" }}>Built</Typography>
                <Typography>2018</Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: "flex", marginBottom: "18px" }}>
                <Typography sx={{ marginRight: "20px" }}>
                  Past viewing
                </Typography>
                <Typography>Aug 15, 2024</Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ marginRight: "20px" }}>
                  Upcoming viewing
                </Typography>
                <Typography>Aug 17, 2024</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            px: 5,
            mt: 5,
          }}
        >
          <LocationMap
            hideContact
            padding={5}
            width={"100%"}
            title="Location"
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default PropertyDetailsModal;
