import React from "react";
import PaymentPlan from "../../components/sections/PaymentPlan/PaymentPlan";
import PaymentMethod from "../../components/sections/PaymentMethod/PaymentMethod";
import { Box, Divider, Typography } from "@mui/material";

const Payment = () => {
  return (
    <Box>
      <Box
        sx={{
          display: { sm: "flex", xs: "block" },
          margin: "auto",
          fontFamily: "Arial, sans-serif",
          backgroundColor: "#ffffff",
          borderRadius: 2,
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        }}
      >
        <PaymentPlan />
        <PaymentMethod />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "68px",
          borderBottom: "1px solid rgba(0, 0, 51, 0.06)",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            fontSize: "14px",
            fontWeight: 400,
            textAlign: "center",
          }}
          variant="body2"
          color="textSecondary"
        >
          © 2024 Home Ai, LLC
        </Typography>
      </Box>
    </Box>
  );
};

export default Payment;
