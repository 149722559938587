import React from 'react'
import Layout from '../Layout/Layout';
import FAQSection from '../../components/sections/FAQ/FAQ';
const FAQ = () => {
   return (
      <Layout backgroundColor={"linear-gradient(to bottom, #d2dbf8, #f7f9ff)"} >
         <FAQSection />
      </Layout>
   )
}

export default FAQ;
