import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  MenuItem,
  Select,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme, useMediaQuery } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LoginSignupModal from "../login/Login";
import ImageComponent from "../Image/Image";
import styles from "./Header.module.css";

const Header = ({ handleClickOpen }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // Check if user is logged in (e.g., by checking local storage or a token)
    const token = localStorage.getItem("user");
    console.log("token", token);
    setIsLoggedIn(token ? true : false);
    console.log("isLoggedIn", isLoggedIn);
  }, []);

  const menuOptions = [
    { to: "/", label: "Home" },
    { to: "/tenants", label: "For Tenants" },
    { to: "/owners", label: "For Owners" },
    { to: "/features", label: "Features" },
    { to: "/plan", label: "Pricing" },
    { to: "/about-us", label: "About Us" },
    { to: "/contact-us", label: "Contact Us" },
  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleOptionClick = (to) => {
    setDrawerOpen(false);
    navigate(to);
  };

  const getStartedHandler = () => {
    navigate("/chat");
  };

  const drawerContent = (
    <Box
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className={styles.drawerContent}
    >
      <List>
        {menuOptions?.map((option, index) => (
          <ListItem key={index}>
            <Link
              to={option.to}
              onClick={() => handleOptionClick(option.to)}
              className={`${styles.drawerLink} ${
                location.pathname === option.to ? styles.drawerLinkActive : ""
              }`}
            >
              <ListItemText primary={option.label} />
            </Link>
          </ListItem>
        ))}
        <ListItem>
          <Select value="ENG" variant="standard" disableUnderline fullWidth>
            <MenuItem value="ENG">ENG</MenuItem>
            <MenuItem value="URD">URD</MenuItem>
          </Select>
        </ListItem>

        <ListItem>
          <Button
            variant="outlined"
            fullWidth
            className={styles.drawerLoginButton}
            onClick={handleClickOpen}
          >
            Log In
          </Button>
        </ListItem>

        <ListItem>
          <Button variant="contained" fullWidth onClick={getStartedHandler}>
            Get Started
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  const handleLoginClick = () => {
    setLoginOpen(true);
  };

  const handleModalClose = () => {
    setLoginOpen(false);
  };

  return (
    <>
      <AppBar position="static" className={styles.appBar}>
        <Container maxWidth="xl">
          <Toolbar className={styles.toolbar}>
            {isMobile ? (
              <>
                <Box>
                  <Typography variant="h6" className={styles.mobileLogoText}>
                    HOME AI
                  </Typography>
                </Box>
                <IconButton
                  edge="end"
                  color={theme.palette.border.grey}
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="right"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                >
                  {drawerContent}
                </Drawer>
              </>
            ) : (
              <Box className={styles.desktopNav}>
                <Box>
                  <Link to="/" className={styles.logoLink}>
                    <ImageComponent
                      name="logo"
                      height={30}
                      alt="HOME AI Logo"
                    />
                  </Link>
                </Box>
                <Box className={styles.menuContainer}>
                  {menuOptions?.map((option, index) => (
                    <Link
                      key={index}
                      to={option.to}
                      className={`${styles.menuLink} ${
                        location.pathname === option.to
                          ? styles.menuLinkActive
                          : ""
                      }`}
                      style={{
                        fontSize: "14px",
                        color:
                          location.pathname === option.to
                            ? `${theme.palette.primary.main}`
                            : "#1A1A1A",

                        borderBottom:
                          location.pathname === option.to
                            ? `2px solid ${theme.palette.primary.lightMain}`
                            : "none",
                      }}
                    >
                      {option.label}
                    </Link>
                  ))}
                </Box>
                <Box className={styles.actionContainer}>
                  <Select
                    value="ENG"
                    variant="standard"
                    disableUnderline
                    className={styles.languageSelector}
                  >
                    <MenuItem value="ENG">ENG</MenuItem>
                    <MenuItem value="URD">URD</MenuItem>
                  </Select>
                  {!isLoggedIn && (
                    <Button
                      variant="outlined"
                      className={styles.loginButton}
                      onClick={handleLoginClick}
                    >
                      Log In
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    onClick={getStartedHandler}
                    className={styles.getStartedButton}
                  >
                    Place and Add
                  </Button>
                </Box>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <LoginSignupModal open={loginOpen} onClose={handleModalClose} />
    </>
  );
};

export default Header;
