import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";

import { NAVIGATION_ROUTE } from "../constants";
const AppRoutes = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      {NAVIGATION_ROUTE.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};

export default AppRoutes;
