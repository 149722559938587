import { Box, Typography } from '@mui/material';
import React from 'react'
import { theme } from '../../../theme/theme';

const Details = ({ details }) => {
   return (
      <Box sx={{
         py: 5
      }} >
         <Box>
            <Typography sx={{
               fontSize: "24px",
               fontWeight: 500,
               color: "black",
               [theme.breakpoints.down("md")]: {
                  fontSize: "20px",

               },
               [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",

               }
            }}>{details.address}</Typography>
            <Box sx={{
               py: 3
            }}>
               <Typography sx={{
                  backgroundColor: "#f4fbf6",
                  border: " 1px solid #cfebdb",
                  color: "#65aa8a",
                  py: 0.5,
                  width: "110px",
                  textAlign: "center",
                  borderRadius: "5px"

               }}>{details.status}</Typography>
            </Box>
         </Box>


         <Box
            sx={{
               width: "80%",
               display: "grid",
               flexDirection: "column",
               gap: 2,

               [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  gridTemplateColumns: "1fr",
               },
              

            }} >
            <Box sx={{
               display: "flex",
               flexGrow: 1,
            }}>
               <Typography sx={{ color: 'text.secondary', flex: 1, fontSize: "12px" }}>Price</Typography>
               <Typography sx={{ flex: 2, fontSize: "12px", fontWeight: 500, color: "black" }}>{details.price} per month</Typography>
               <Typography sx={{ color: 'text.secondary', flex: 1, fontSize: "12px" }}>Size</Typography>
               <Typography sx={{ flex: 2, fontSize: "12px", fontWeight: 500, color: "black" }}>{details.sqrFeet} sqft</Typography>
               <Typography sx={{ color: 'text.secondary', flex: 1, fontSize: "12px" }}>Past viewing</Typography>
               <Typography sx={{ flex: 3, fontSize: "12px", fontWeight: 500, color: "black" }}>{details.past}</Typography>
            </Box>

            <Box
               sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
               }}>
               <Typography sx={{ color: 'text.secondary', flex: 1, fontSize: "12px" }}>Rooms</Typography>
               <Typography sx={{ flex: 2, fontWeight: 500, color: "black", fontSize: "12px" }}>{details.beds} Bed / {details.baths} Ba</Typography>
               <Typography sx={{ color: 'text.secondary', flex: 1, fontSize: "12px" }}>Built</Typography>
               <Typography sx={{ flex: 2, fontSize: "12px", fontWeight: 500, color: "black" }}>{details.built}</Typography>
               <Typography sx={{ color: 'text.secondary', flex: 1, fontSize: "12px" }}>Upcoming viewing</Typography>
               <Typography sx={{ flex: 3, fontSize: "12px", fontWeight: 500, color: "black" }}>{details.upcoming}</Typography>
            </Box>
         </Box>
      </Box>
   )
}

export default Details;