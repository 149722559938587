export const Picture = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Components / image">
      <rect width="24" height="24" fill="white" fill-opacity="0.01" />
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.99961 1.59961H19.9996C21.325 1.59961 22.3996 2.67412 22.3996 3.99961V19.9996C22.3996 21.325 21.325 22.3996 19.9996 22.3996H3.99961C2.67412 22.3996 1.59961 21.325 1.59961 19.9996V3.99961C1.59961 2.67412 2.67412 1.59961 3.99961 1.59961ZM3.99961 3.19961C3.55779 3.19961 3.19961 3.55779 3.19961 3.99961V13.3814L5.89049 10.6905C6.02855 10.5524 6.21676 10.4764 6.41199 10.4797C6.60721 10.4831 6.7927 10.5656 6.92593 10.7083L12.5973 16.7838L17.0905 12.2905C17.3716 12.0093 17.8276 12.0093 18.1087 12.2905L20.7996 14.9814V3.99961C20.7996 3.55779 20.4414 3.19961 19.9996 3.19961H3.99961ZM3.19961 19.9996V15.4178L6.3818 12.2357L12.0494 18.307L14.3054 20.7996H3.99961C3.55779 20.7996 3.19961 20.4414 3.19961 19.9996ZM19.9996 20.7996H16.2476L13.573 17.8444L17.5996 13.8178L20.7996 17.0178V19.9996C20.7996 20.4414 20.4414 20.7996 19.9996 20.7996ZM10.6384 8.79961C10.6384 8.04782 11.2478 7.43836 11.9996 7.43836C12.7514 7.43836 13.3609 8.04782 13.3609 8.79961C13.3609 9.5514 12.7514 10.1609 11.9996 10.1609C11.2478 10.1609 10.6384 9.5514 10.6384 8.79961ZM11.9996 5.99836C10.4525 5.99836 9.19836 7.25252 9.19836 8.79961C9.19836 10.3467 10.4525 11.6009 11.9996 11.6009C13.5467 11.6009 14.8009 10.3467 14.8009 8.79961C14.8009 7.25252 13.5467 5.99836 11.9996 5.99836Z"
        fill="#646464"
      />
    </g>
  </svg>
);
