import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, styled, Typography } from "@mui/material";
import Plan from "../../../plan/plan";
import { jsonData } from "../../../../db";
import CancelSubscription from "../../../modals/cancelSubscription";
import { useState } from "react";
import {
  selectSubscriptions,
  getPlans,
} from "../../../../store/slices/subscriptionsSlice";

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const PlansContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  alignItems: "flex-start",
  marginTop: "10px",
  paddingLeft: 16,
  maxWidth: 626,
}));

const Subscription = () => {
  const dispatch = useDispatch();
  const { plans } = useSelector(selectSubscriptions);

  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const { subscription } = useSelector(selectSubscriptions);
  console.log(subscription);

  useEffect(() => {
    dispatch(getPlans());
  }, [dispatch]);

  const getActive = () => {
    if (!subscription?.data || !plans?.data) {
      return null;
    }

    const activeSubscription = subscription.data.find(
      (item) => item.status === "active"
    );

    if (!activeSubscription) {
      return null;
    }

    return plans.data.find((plan) => plan.plan === activeSubscription.plan);
  };

  console.log(getActive());

  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <HeaderContainer>
        <Typography variant="h6" fontWeight="normal">
          Subscription
        </Typography>
      </HeaderContainer>
      <PlansContainer>
        {/* <Plan
          name={jsonData.currentPlan.name}
          price={jsonData.currentPlan.price}
          keyFeatures={jsonData.currentPlan.keyFeatures}
        /> */}
        <Plan
          name={getActive().plan_name}
          price={
            getActive().unit_amount !== 0 ? getActive().unit_amount : "Free"
          }
          cancelSubscription={() => setCancelSubscriptionModal(true)}
          cancel
          subscription
          keyFeatures={jsonData.currentPlan.keyFeatures}
        />
        <Plan
          name={jsonData.upgradePlan.name}
          noScroll
          price={jsonData.upgradePlan.price}
          keyFeatures={jsonData.upgradePlan.keyFeatures}
          isUpgrade={true}
        />
      </PlansContainer>
      <CancelSubscription
        open={cancelSubscriptionModal}
        onClose={() => setCancelSubscriptionModal(false)}
      />
    </Box>
  );
};

export default Subscription;
