import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  ReferenceLine,
} from "recharts";
import { Typography, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { getAnalytics } from "../../../../store/slices/analyticsSlice";

const data = [
  { name: "Studio flat", value: 5.3 },
  { name: "1 bed flat", value: 20.8 },
  { name: "2 bed flat", value: 22.1 },
  { name: "3+ bed flat", value: 8.2 },
  { name: "1 bed house", value: 2.8 },
  { name: "2 bed house", value: 13.0 },
  { name: "3 bed house", value: 14.9 },
  { name: "4+ bed house", value: 12.8 },
];

const predictiveData = [
  { year: 2023, value: 2200 },
  { year: 2024, value: 2150 },
  { year: 2025, value: 2100 },
  { year: 2026, value: 2250 },
  { year: 2027, value: 2200 },
  { year: 2028, value: 2300 },
  { year: 2029, value: 2150 },
  { year: 2030, value: 2400 },
];

const PredictiveInsightsChart = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={predictiveData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="year"
          axisLine={false}
          tickLine={false}
          tick={{ fill: "#666", fontSize: 12 }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={{ fill: "#666", fontSize: 12 }}
          tickFormatter={(value) => `F${value / 1000}k`}
        />
        <Tooltip
          formatter={(value) => [`F${value}`, "Price"]}
          labelFormatter={(label) => `Year: ${label}`}
        />
        <ReferenceLine x="2026" stroke="#3E63DD" strokeWidth={2} />
        <defs>
          <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3E63DD" stopOpacity={0.2} />
            <stop offset="95%" stopColor="#3E63DD" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="value"
          stroke="#3E63DD"
          fillOpacity={1}
          fill="url(#colorGradient)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const MarketAnalytics = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnalytics());
  }, [dispatch]);

  return (
    <Box
      sx={{
        py: 3,
        px: "22px",
        height: "100vh",
        overflowX: "hidden",
        overflowY: "auto",
        display: "grid",
        gap: "24px",
      }}
    >
      <Typography
        sx={{ fontSize: "24px", fontWeight: "500", mb: "10px" }}
        variant="h4"
      >
        Market analytics
      </Typography>
      <Typography variant="h6" gutterBottom>
        Market analytics
      </Typography>
      <Box
        sx={{
          border: "1px solid #C1D0FF",
          background: "#FFF",
          borderRadius: "8px",
          padding: "12px 16px",
          height: "284px",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ fontSize: "18px", fontWeight: 700 }}
        >
          Homes rented by type and size, 2024
        </Typography>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            barSize={32}
            barGap={8}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#E0E0E0" />
            <XAxis
              dataKey="name"
              interval={0}
              tick={{ fontSize: 12 }}
              height={80}
            />
            <YAxis
              tickFormatter={(value) => `${value}%`}
              domain={[0, 25]}
              ticks={[0, 5, 10, 15, 20, 25]}
            />
            <Tooltip formatter={(value) => `${value}%`} />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Typography variant="h6" gutterBottom>
        Price trends
      </Typography>
      <Box
        sx={{
          border: "1px solid #C1D0FF",
          background: "#FFF",
          borderRadius: "8px",
          padding: "12px 16px",
          height: "284px",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ fontSize: "18px", fontWeight: 700 }}
        >
          Homes rented by type and size, 2024
        </Typography>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            barSize={32}
            barGap={8}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#E0E0E0" />
            <XAxis
              dataKey="name"
              interval={0}
              tick={{ fontSize: 12 }}
              height={80}
            />
            <YAxis
              tickFormatter={(value) => `${value}%`}
              domain={[0, 25]}
              ticks={[0, 5, 10, 15, 20, 25]}
            />
            <Tooltip formatter={(value) => `${value}%`} />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Typography variant="h6" gutterBottom>
        Predictive insights
      </Typography>
      <Box
        sx={{
          border: "1px solid #C1D0FF",
          background: "#FFF",
          borderRadius: "8px",
          padding: "12px 16px",
          height: "284px",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ fontSize: "18px", fontWeight: 700 }}
        >
          Homes rented by type and size, 2024
        </Typography>
        <PredictiveInsightsChart />
      </Box>
    </Box>
  );
};

export default MarketAnalytics;
