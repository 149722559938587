import { Box } from "@mui/material";
import LoggedInHeader from "../../components/loggedInHeader/loggedInHeader";

const LoggedInLayout = ({ children, hideSubscription, hideProfile }) => {
  return (
    <Box>
      <LoggedInHeader
        hideSubscription={hideSubscription}
        hideProfile={hideProfile}
      />
      {children}
    </Box>
  );
};

export default LoggedInLayout;
