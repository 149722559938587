import React, { useRef } from "react";
import UploadImage from "./ImageUpload";
import UploadedImages from "./UploadedImages";
import { styled } from "@mui/material";

const HiddenInput = styled("input")({
  display: "none",
});

const Upload = ({ onFileSelect, onFileDelete, images }) => {
  const inputRef = useRef(null);

  const handleFiles = (files) => {
    const file = files[0];
    if (
      file &&
      ["image/jpeg", "image/png"].includes(file.type) &&
      file.size <= 10 * 1024 * 1024
    ) {
      onFileSelect(file);
    } else {
      alert("Please upload a JPG, PNG, or JPEG file, no larger than 10MB.");
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  return (
    <>
      {images.length ? (
        <UploadedImages
          inputRef={inputRef}
          images={images}
          onDelete={onFileDelete}
        />
      ) : (
        <UploadImage btnText={"Uplad Video"} description={"AVI, MP4 file size no more than 100MB"} inputRef={inputRef} handleFiles={handleFiles} />
      )}
      <HiddenInput
        ref={inputRef}
        type="file"
        accept="image/jpeg,image/png"
        onChange={handleChange}
      />
    </>
  );
};

export default Upload;
