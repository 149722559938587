import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Collapse,
  CardActions,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useState } from "react";
import { CaretDown } from "../../../assets/svg/CaretDown";

const FeatureCard = ({ feature, selected }) => {
  const [plan, setplans] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const checkIconStyle = {
    marginRight: 0.5,
    color: "#4CAF50",
    fontSize: "16px",
    borderRadius: "100%",
  };

  return (
    <li
      key={feature.title}
      style={{
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          backgroundColor: "transparent",
          boxShadow: "unset",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              padding: 0,
            }}
          >
            <CheckCircleOutlineIcon
              sx={{
                ...checkIconStyle,
                color: selected ? "white" : checkIconStyle.color,
                backgroundColor: selected
                  ? "rgba(255, 255, 255, 0.5)"
                  : "rgba(76, 175, 80, 0.1)",
              }}
            />
            <Typography
              variant="body2"
              sx={{
                color: selected ? "#fff" : "#202020",
              }}
            >
              {feature.title}
            </Typography>
          </CardContent>
          <CardActions sx={{ padding: 0 }} disableSpacing>
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              sx={{ padding: 0 }}
            >
              <CaretDown fill={selected ? "#fff" : "#202020"} />
            </IconButton>
          </CardActions>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ paddingBottom: "0!important" }}>
            <Typography sx={{ marginBottom: 0 }} paragraph>
              {feature.description}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </li>
  );
};

export default FeatureCard;
