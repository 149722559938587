import { Box, IconButton } from "@mui/material";
import ImageComponent from "../Image/Image";
import Avatar from "../avatar/Avatar";
import UpgradePlan from "../upgradePlan/UpgradePlan";
import { useEffect, useMemo, useRef, useState } from "react";
import ProfileDropdown from "./ProfileDropdown";
import { useSelector } from "react-redux";
import { selectSubscriptions } from "../../store/slices/subscriptionsSlice";
import { selectAccount } from "../../store/slices/accountSlice";
const LoggedInHeader = ({ hideProfile, hideSubscription }) => {
  const { subscription } = useSelector(selectSubscriptions);
  const { data: userData } = useSelector(selectAccount);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const subscriptionPlan = useMemo(() => {
    return (
      subscription?.data &&
      subscription?.data.find(
        (subscription) => subscription.status === "active"
      )
    );
  }, [subscription]);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : "";
    const lastInitial = lastName ? lastName[0].toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };

  const userInitials = useMemo(() => {
    return getInitials(userData?.firstname, userData?.lastname);
  }, [userData]);

  return (
    <Box
      sx={{
        height: "64px",
        background: "#202020",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        px: "40px",
      }}
    >
      <ImageComponent name="logo-white" height={26} alt="HOME AI Logo" />
      {!hideProfile && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "40px",
          }}
        >
          {!hideSubscription &&
            (!subscriptionPlan || subscriptionPlan.plan === "free") && (
              <UpgradePlan
                title="Upgrade Plan"
                text="Real-Time Market Scan and more"
                color="#FFCC00"
              />
            )}
          <IconButton
            onMouseEnter={handleMouseEnter}
            sx={{ cursor: "pointer" }}
            size="small"
            aria-controls={open ? "profile-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar name={userInitials} />
          </IconButton>
          <ProfileDropdown
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            open={open}
            handleMouseLeave={handleMouseLeave}
          />
        </Box>
      )}
    </Box>
  );
};

export default LoggedInHeader;
