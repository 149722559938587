import React from 'react'
import Layout from '../Layout/Layout';
import HowItWorks from '../../components/sections/workSection/WorkSection';
const HowItWork = () => {
   const heading = "How It Works";
   const text = "Discover how Home Al transforms your apartment search into a seamless experience.Our intelligent platform leverages advanced algorithms to connect you with the best listings based on your unique preferences."
   return (
      <Layout heading={heading} text={text}>
         <HowItWorks title={"Explore the Process"} />
      </Layout>
   )
}

export default HowItWork;