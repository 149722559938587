import React from "react";
import { Box, Paper, Typography } from "@mui/material";

import Location from "../../pages/HomeDetails/subComponents/Location";
import { theme } from "../../theme/theme";
import ProgressBar from "../Progress/ProgressBar";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import PhotoSizeSelectSmallOutlinedIcon from "@mui/icons-material/PhotoSizeSelectSmallOutlined";
import KingBedOutlinedIcon from "@mui/icons-material/KingBedOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import ParkOutlinedIcon from "@mui/icons-material/ParkOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { SuitCase } from "../../assets/svg/SuitCase";
import { Coin } from "../../assets/svg/Coin";
import { FlowerLotus } from "../../assets/svg/FlowerLotus";
import { Bank } from "../../assets/svg/Bank";
import { CalendarBlank } from "../../assets/svg/CalendarBlank";

const ClientOverview = ({ noPadding }) => {
  const paperHeadings = {
    fontSize: "16px",
    color: "black",
    fontWeight: 500,
  };
  const outerBox = {
    display: "flex",
    flexDirection: "column",
    p: 2,
    mb: 3,
    boxShadow: "none",
    backgroundColor: theme.palette.background.skyBlue,
    border: `1.5px solid ${theme.palette.border.blue}`,
    rowGap: 1,
    borderRadius: "10px",
  };
  const innerBox = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: 1,
  };
  const mainText = {
    ml: 1,
    fontSize: "12px",
    fontWeight: 550,
    color: "black",
  };
  const subText = {
    fontSize: "12px",
    ml: 1,
  };
  const subPaper = {
    p: 1,
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    border: `1.5px solid ${theme.palette.border.blue}`,
    width: "150px",
    flexGrow: 1,
  };
  const iconStyle = {
    p: 1,
    height: "26px",
    width: "26px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <Box p={!noPadding ? 2 : 0}>
      {/* Client Overview Section */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          Client Overview
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          <Paper sx={subPaper}>
            <Box
              sx={{
                ...iconStyle,
                backgroundColor: theme.palette.background.skyBlue,
              }}
            >
              <SuitCase />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={mainText}>Zurich Oerlikon</Typography>
              <Typography sx={subText}>Work</Typography>
            </Box>
          </Paper>
          <Paper variant="outlined" sx={subPaper}>
            <Box
              sx={{
                ...iconStyle,
                backgroundColor: "#daf1db",
              }}
            >
              <Coin />
            </Box>
            <Box>
              <Typography sx={mainText}>2,500</Typography>
              <Typography sx={subText}>Budget</Typography>
            </Box>
          </Paper>
          <Paper sx={subPaper}>
            <Box sx={{ backgroundColor: "#fffab8", ...iconStyle }}>
              <FlowerLotus />
            </Box>
            <Box>
              <Typography sx={mainText}>Central areas</Typography>
              <Typography sx={subText}>Lifestyle</Typography>
            </Box>
          </Paper>
        </Box>
        {/* Dummy map */}
        <Box>
          <Paper
            variant="outlined"
            sx={{
              boxShadow: "none",
              border: `1.5px solid ${theme.palette.border.blue}`,
              paddingBottom: 0,
            }}
          >
            <Location />
          </Paper>
        </Box>
      </Box>

      {/* Budget and Lease Terms */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          Budget and Lease Terms
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Paper
            variant="outlined"
            sx={{
              ...subPaper,
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={mainText}>Month Budget</Typography>
              <Typography sx={subText}>F2,500</Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <ProgressBar value={70} />
            </Box>
          </Paper>
          <Paper variant="outlined" sx={subPaper}>
            <Box></Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box sx={{ ...iconStyle, backgroundColor: "#daf1db" }}>
                <Bank />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography sx={mainText}>Standard</Typography>
                <Typography sx={subText}>Deposit</Typography>
              </Box>
            </Box>
          </Paper>
          <Paper variant="outlined" sx={subPaper}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box sx={{ ...iconStyle, backgroundColor: "#fffab8" }}>
                <CalendarBlank />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography sx={mainText}>Long-term</Typography>
                <Typography sx={subText}>Lease duration</Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>

      {/* Apartment Requirements */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          Apartment Requirements
        </Typography>
        <Box sx={innerBox}>
          <Paper
            sx={{
              ...subPaper,
              flexDirection: "column",
              alignItems: "left",
              gap: 0.5,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center", // Vertically centers the icon and text
                color: theme.palette.text.secondary,
                fontWeight: 200,
                ...mainText,
              }}
            >
              <PhotoSizeSelectSmallOutlinedIcon
                sx={{ fontSize: 18, marginRight: 0.5 }}
              />
              Room Size
            </Typography>
            <Typography sx={{ ...subText, fontWeight: 600, color: "black" }}>
              2.5 rooms
            </Typography>
          </Paper>

          <Paper
            sx={{
              ...subPaper,
              flexDirection: "column",
              alignItems: "left",
              gap: 0.5,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center", // Vertically centers the icon and text
                color: theme.palette.text.secondary,
                fontWeight: 200,
                ...mainText,
              }}
            >
              {" "}
              <KingBedOutlinedIcon
                sx={{ fontSize: 18, marginRight: 0.5 }}
              />{" "}
              Furnishing
            </Typography>
            <Typography sx={{ ...subText, fontWeight: 600, color: "black" }}>
              No
            </Typography>
          </Paper>

          <Paper
            sx={{
              ...subPaper,
              flexDirection: "column",
              alignItems: "left",
              gap: 1.5,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                color: theme.palette.text.secondary,
                fontWeight: 200,
                ...mainText,
              }}
            >
              <ApartmentOutlinedIcon sx={{ fontSize: 18, marginRight: 0.5 }} />
              Amenities
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                ml: 1,
              }}
            >
              <PetsOutlinedIcon
                sx={{
                  fontSize: 18,
                  fontWeight: 400,
                }}
              />
              <MeetingRoomOutlinedIcon
                sx={{
                  fontSize: 18,
                  fontWeight: 400,
                }}
              />
              <ParkOutlinedIcon
                sx={{
                  fontSize: 18,
                  fontWeight: 400,
                }}
              />
            </Box>
          </Paper>
        </Box>
      </Box>

      {/* Lifestyle and Neighborhood Preferences */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          Lifestyle and Neighborhood Preferences
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
          }}
        >
          <ApartmentOutlinedIcon
            sx={{
              fontSize: 18,
              fontWeight: 400,
            }}
          />
          <StorefrontOutlinedIcon
            sx={{
              fontSize: 18,
              fontWeight: 400,
            }}
          />
          <PermIdentityIcon
            sx={{
              fontSize: 18,
              fontWeight: 400,
            }}
          />
        </Box>
      </Box>

      {/* Move-in Timing */}
      <Box sx={outerBox}>
        <Typography sx={paperHeadings} gutterBottom>
          Move-in Timing
        </Typography>
        <Box>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              color: theme.palette.text.secondary,
              fontWeight: 200,
              ...mainText,
            }}
          >
            <CalendarTodayOutlinedIcon
              sx={{ fontSize: 18, marginRight: 0.5, ml: -1 }}
            />{" "}
            2 months
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientOverview;
