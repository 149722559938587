import React from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { theme } from "../../theme/theme";
import authService from "../../services/authService";

const InputField = styled(TextField)({
  marginBottom: "20px",
});

const SignupForm = ({ onBack, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      firstname: "",
      lastname: "",
      user_type: "tenant",
      status: "active",
      language: "de",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(8, "Must be at least 8 characters")
        .required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
      firstname: Yup.string().required("Required"),
      lastname: Yup.string().required("Required"),
      user_type: Yup.string().oneOf(["tenant", "owner"]).required("Required"),
      language: Yup.string().oneOf(["de", "en"]).required("Required"),
    }),
    onSubmit: async (values) => {
      const { confirmPassword, ...dataToSend } = values;
      onSubmit(dataToSend);
      // await authService.register(dataToSend);
      console.log("Signup data sent to server:", dataToSend);
      // Here you would typically send the data to your server
      // For example: axios.post('/api/signup', dataToSend)
    },
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      sx={{ mt: 1 }}
    >
      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        Email Address
      </FormLabel>
      <InputField
        size="small"
        fullWidth
        id="email"
        name="email"
        placeholder="Enter your Email"
        {...formik.getFieldProps("email")}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        variant="outlined"
      />

      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        Password
      </FormLabel>
      <InputField
        size="small"
        fullWidth
        name="password"
        type="password"
        placeholder="Enter your Password"
        {...formik.getFieldProps("password")}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        variant="outlined"
      />

      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        Confirm Password
      </FormLabel>
      <InputField
        size="small"
        fullWidth
        name="confirmPassword"
        type="password"
        placeholder="Confirm your Password"
        {...formik.getFieldProps("confirmPassword")}
        error={
          formik.touched.confirmPassword &&
          Boolean(formik.errors.confirmPassword)
        }
        helperText={
          formik.touched.confirmPassword && formik.errors.confirmPassword
        }
        variant="outlined"
      />

      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        First Name
      </FormLabel>
      <InputField
        size="small"
        fullWidth
        name="firstname"
        placeholder="Enter your First Name"
        {...formik.getFieldProps("firstname")}
        error={formik.touched.firstname && Boolean(formik.errors.firstname)}
        helperText={formik.touched.firstname && formik.errors.firstname}
        variant="outlined"
      />

      <FormLabel
        component="legend"
        sx={{ color: theme.palette.text.primary, mb: 1 }}
      >
        Last Name
      </FormLabel>
      <InputField
        size="small"
        fullWidth
        name="lastname"
        placeholder="Enter your Last Name"
        {...formik.getFieldProps("lastname")}
        error={formik.touched.lastname && Boolean(formik.errors.lastname)}
        helperText={formik.touched.lastname && formik.errors.lastname}
        variant="outlined"
      />

      <FormControl fullWidth sx={{ mb: 2 }}>
        <FormLabel
          component="legend"
          sx={{ color: theme.palette.text.primary, mb: 1 }}
        >
          User Type
        </FormLabel>
        <Select
          size="small"
          {...formik.getFieldProps("user_type")}
          error={formik.touched.user_type && Boolean(formik.errors.user_type)}
        >
          <MenuItem value="tenant">Tenant</MenuItem>
          <MenuItem value="owner">Owner</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mb: 2 }}>
        <FormLabel
          component="legend"
          sx={{ color: theme.palette.text.primary, mb: 1 }}
        >
          Language
        </FormLabel>
        <Select
          size="small"
          {...formik.getFieldProps("language")}
          error={formik.touched.language && Boolean(formik.errors.language)}
        >
          <MenuItem value="de">German</MenuItem>
          <MenuItem value="en">English</MenuItem>
        </Select>
      </FormControl>

      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2, float: "right", width: "auto" }}
        disabled={!formik.isValid || formik.isSubmitting}
      >
        Sign Up
      </Button>
    </Box>
  );
};

export default SignupForm;
