import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { jsonData } from "../../../../db";

const tabs = [
  "All",
  "Trends",
  "Design",
  "Buying",
  "Selling",
  "Rental",
  "Investment",
];

const Onboarding = () => {
  const [selectedTab, setSelectedTab] = useState("All");

  return (
    <Box
      sx={{
        py: 3,
        px: "22px",
        height: "100vh",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <Typography sx={{ fontSize: "24px", fontWeight: "500" }} variant="h4">
        Onboarding for Landlords
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: "32px" }}>
        {tabs.map((tab) => (
          <Box
            onClick={() => setSelectedTab(tab)}
            sx={{
              borderRadius: "28px",
              transition: ".3s all ease",
              px: "12px",
              py: "2px",
              cursor: "pointer",
              background:
                selectedTab === tab ? "rgba(193, 208, 255, 0.50)" : "",
            }}
          >
            <Typography
              sx={{
                color: selectedTab === tab ? "#1F2D5C" : "#4F4F4F",
                fontWeight: 400,
                fontSize: 16,
              }}
              variant="body1"
            >
              {tab}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "12px", mt: "32px" }}>
        {jsonData.blogPosts.map(({ image, title }) => (
          <Box
            sx={{
              width: "355px",
              borderRadius: "8px",
              border: "1px solid #C1D0FF",
              background: "#FFF",
              overflow: "hidden",
              pb: 1,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "200px",
                backgroundImage: `url(${image})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
            <Box
              sx={{
                mt: 1,
                py: 2,
                px: "12px",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  padding: "2px 8px",
                  backgroundColor: "rgba(193, 208, 255, 0.50);",
                  borderRadius: "24px",
                  width: "max-content",
                }}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 500, color: "#1F2D5C" }}
                  variant="body1"
                >
                  Trends
                </Typography>
              </Box>
              <Typography
                sx={{ fontSize: 24, fontWeight: 500, color: "#202020", mb: 0 }}
                variant="h5"
              >
                The Impact of Rising Interest Rates on Housing Affordability
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Onboarding;
